import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import GlobalStyles from 'components/GlobalStyles';
import Header from 'components/Header';
import Footer from 'components/Footer';

import { LayoutStyled } from './LayoutStyled.js';

export default function Layout({ children, theme }) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <GlobalStyles />
          <LayoutStyled theme={theme}>
            <Header siteTitle={data.site.siteMetadata.title} theme={theme} />
            {children}
            <Footer />
          </LayoutStyled>
        </>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string,
};

Layout.defaultProps = {
  theme: 'neutral',
};
