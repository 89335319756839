import styled from '@emotion/styled';

export const FooterStyled = styled('footer')({
  // margin: '0 auto',
  // maxWidth: 960,
  // paddingTop: '24px',
  //
  color: 'white',
  background: '#444444',
  textAlign: 'center',
  fontSize: '0.7em',
  letterSpacing: '0.3em',
  position: 'absolute',
  bottom: 0,
  width: '100%',
  zIndex: 1,

  a: {
    padding: 10,
    display: 'block',
    color: '#ffff91'
  }
});
