module.exports = {
  siteMetadata: {
    title: 'Beach.Koeln',
    siteTitle: 'Beach.Koeln',
    siteUrl: 'https://beach.koeln', // No trailing slash (used to create xml sitemap)
  },
  webfontconfig: {
    google: {
      families: ['Open Sans:400,700', 'Montserrat:400,600,700,900'],
    },
  },
  responsive: {
    spacing: {
      default: 24,
    },
    breakpoints: {
      mobile: 0,
      tablet: 481,
      tabletFluid: 737,
      laptop: 977,
      desktop: 1440,
    },
    wrapSizes: {
      mobile: '100%',
      tablet: '100%',
      tabletFluid: 720,
      laptop: 960,
      desktop: 1240,
    },
  },
};
