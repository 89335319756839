import React from 'react';
import PropTypes from 'prop-types';

import Logo from 'components/Logo';
import HeaderNav from './HeaderNav';
import { HeaderStyled } from './HeaderStyled.js';

export default function Header({theme}) {
  return (
    <HeaderStyled theme={theme}>
      <Logo theme={theme} />
      <HeaderNav theme={theme} />
    </HeaderStyled>
  );
}

Header.propTypes = {
  theme: PropTypes.string,
};

Header.defaultProps = {
  theme: 'neutral',
};
