import { styled } from 'utils/emotion'
import media from 'utils/mediaqueries'

export const HeaderStyled = styled('header')({
})

export const HeaderNavStyled = styled('div')({
    position: 'fixed',
    width: '100%',
    left: 0,
    zIndex: 1,
    textAlign: 'left',
    paddingLeft: 50,
    top: 150,

    [media('desktop')]: {
      textAlign: 'left',
      padding: '50px 0px',
      top: 0,
    },

    a: {
      display: 'block',
      padding: '10px 0px',

      [media('desktop')]: {
        display: 'inline',
        padding: '10px 20px',
      }
    },

    'a:first-of-type': {
      paddingLeft: 0,
    },

    'a:last-child': {
      paddingRight: 0,
    },

    nav: {
      fontSize: '0.7em',
      letterSpacing: '0.3em',
      textAlign: 'left',

      [media('desktop')]: {
        textAlign: 'center',
      }
    }
},

props =>
  props.theme === 'yellow' && {
    a: {
      color: '#ffff91',
    },
    'a:hover': {
      textDecorationColor: '#ffff91',
    },
  },

props =>
  props.theme === 'dark' && {
    a: {
      color: '#ff0056',
    },
    'a:hover': {
      textDecorationColor: '#ff0056',
    },
  }
)
