import React from 'react'
import { Global } from '@emotion/core'
import media from 'utils/mediaqueries'

export default function GlobalStyles() {
  return (
    <Global
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box'
        },

        html: {
          fontFamily: 'brandon-grotesque, sans-serif',
          fontSize: 20
        },

        body: {
          position: 'relative',
          overflowX: 'hidden',
        },

        h2: {
          fontSize: '2em',
          marginBottom: 20,
          marginTop: 80,
          lineHeight: '1.3em',
          fontWeight: 900,
          letterSpacing: 1,
          fontFamily: 'brandon-grotesque, sans-serif',
          textTransform: 'uppercase',
          maxWidth: 600,
          position: 'fixed',

          [media('desktop')]: {
            fontSize: '5em',
          },
        },

        'h2 span': {
          fontWeight: 100,
        },

        'h2:first-of-type': {
          marginTop: 0,
        },

        h3: {
          fontSize: '2.5em',
          marginBottom: 20,
          marginTop: 50,
          fontWeight: 700,
          maxWidth: 500,
          fontFamily: 'brandon-grotesque, sans-serif',
          color: '#005c82',
          lineHeight: '1.1em',

          [media('desktop')]: {
            fontSize: '3em',
          },
        },

        h4: {
          fontSize: '1.5em',
          marginBottom: 20,
          marginTop: 30,
          fontWeight: 600,
          letterSpacing: 1,
          fontFamily: 'brandon-grotesque, sans-serif',
          // textTransform: 'uppercase',
        },

        p: {
          fontSize: '1.2em',
          marginBottom: 20,
          marginTop: 30,
          fontWeight: 300,
          letterSpacing: 1,
          fontFamily: 'brandon-grotesque, sans-serif',
          lineHeight: '1.8em',
          maxWidth: 900,
        },

        'p.hint': {
          fontStyle: 'italic',
          color: '#555',
          fontSize: '0.8em',
        },

        ul: {
          paddingLeft: 30,
          marginBottom: 20,
          fontSize: '1.3em',
          fontWeight: 300,
          lineHeight: '1.8em',
        },

        li: {
          // listStyle: 'none',
          position: 'relative',
        },

        // 'li:before': {
        //   fontSize: '1em',
        //   content: '+',
        //   color: '#005c82',
        //   display: 'inline-block',
        //   width: '1em',
        //   marginLeft: '-1em',
        // },

        blockquote: {
          position: 'relative',
          margin: '80px auto',
          width: 400,
          maxWidth: '100%',
          fontSize: 48,
          fontWeight: 100,
          fontStyle: 'italic',
          // lineHeight: ,
          paddingLeft: 40,
          borderLeft: '2px solid #005c82',
        },

        'blockquote span': {
          display: 'block',
          textAlign: 'right',
          fontSize: 16,
          color: '#005c82',
          fontWeight: 500,
          // lineHeight: 40,
          marginTop: 10,
          // textTransform: 'uppercase',
        },

        a: {
          textDecoration: 'none',
          color: 'black',
          fontWeight: 500,
        },

        'a:visited': {
          color: 'inherit',
        },

        'a:hover': {
          /* color: white, */
          textDecoration: 'underline',
          textDecorationStyle: 'wavy',
          textDecorationSkip: 'ink',
        },

        'a.button': {
          display: 'inline-block',
          border: '2px solid #005c82',
          padding: '5px 20px',
          color: '#005c82',
          borderRadius: 5,
          transition: 'all 80ms ease-in-out',
        },

        'a.button:hover': {
          textDecoration: 'none',
          color: 'white',
          backgroundColor: '#005c82',
        },
      }}
    />
  )
}
