import React from 'react';
import { Link } from 'gatsby';

import { FooterStyled } from './FooterStyled';

export default function Footer() {
  return (
    <FooterStyled>
      <nav>
        <Link to="/impressum/">impressum</Link>
      </nav>
    </FooterStyled>
  );
}
