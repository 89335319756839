import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import { LogoStyled } from './LogoStyled';

export default function Logo({ title, theme }) {
  const [scrolled, setScrolled] = useState(false)
  const scrollRef = useRef()

  scrollRef.current = scrolled
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 300
      if (scrollRef.current !== show) {
        setScrolled(show)
      }
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <LogoStyled theme={theme} style={{opacity: scrolled ? 0 : 1}}>
      <Icon type="logoBeachKoeln" title={title} />
    </LogoStyled>
  );
}

Logo.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.string,
};

Logo.defaultProps = {
  title: 'Beach Köln',
  theme: 'neutral',
};
