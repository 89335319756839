import React from 'react';

export function LogoBeachKoeln() {
  return (
    <>
      <path
        d="M145.77,138.76c0,34.76-28.31,57.47-63.35,57.47H0V0h76.81c34.2,0,61.95,22.15,61.95,56.06
        c0,16.26-6.45,29.15-17.1,38.4C136.24,103.72,145.77,118.86,145.77,138.76z M44.85,42.05v34.76h31.96c10.09,0,17.1-7.29,17.1-17.38
        s-6.73-17.38-17.1-17.38H44.85z M100.92,135.39c0-10.93-7.29-18.78-18.5-18.78H44.85v37.56h37.56
        C93.63,154.18,100.92,146.33,100.92,135.39z M387.24,153.05v43.17H263.9V0h121.94v43.17h-77.09v32.52h70.08v42.61h-70.08v34.76
        H387.24z M622.68,165.39H549.8l-9.81,30.83h-49.06L557.65,0h57.19l66.72,196.22h-49.06L622.68,165.39z M609.23,123.34L586.24,51.3
        l-22.99,72.04H609.23z M773.69,98.11c0-57.75,42.89-102.04,102.04-102.04c35.6,0,67,17.66,84.1,45.41l-38.68,22.43
        c-8.69-15.14-25.51-24.11-45.41-24.11c-34.76,0-57.18,23.27-57.18,58.31c0,35.04,22.43,58.31,57.18,58.31
        c19.9,0,37-8.97,45.41-24.11l38.68,22.43c-16.82,27.75-48.22,45.41-84.1,45.41C816.58,200.15,773.69,155.86,773.69,98.11z
         M1225.32,0v196.22h-44.85v-77.93h-61.67v77.93h-44.85V0h44.85v75.13h61.67V0H1225.32z M107.64,616.71l-62.79-90.82v90.82H0V420.49
        h44.85v85.22l59.99-85.22h51.02L89.7,516.08l68.96,100.63H107.64z M657.64,573.54v43.17H542.71V420.49h44.85v153.05H657.64z
         M920.56,420.49v196.22h-33.64l-75.69-106.52v106.52h-44.85V420.49h33.64l75.69,106.52V420.49H920.56z M292.9,462.69
        c28.34-28.34,74.3-28.34,102.64,0c28.34,28.34,28.34,74.3,0,102.64c-28.34,28.34-74.3,28.34-102.64,0S264.55,491.04,292.9,462.69z
         M235.31,484.83c1.73-6.45,8.36-10.28,14.81-8.55c6.45,1.73,10.28,8.36,8.55,14.81c-1.73,6.45-8.36,10.28-14.81,8.55
        C237.41,497.92,233.58,491.29,235.31,484.83z M235.31,543.2c-1.73-6.45,2.1-13.09,8.55-14.81c6.45-1.73,13.08,2.1,14.81,8.55
        c1.73,6.45-2.1,13.08-8.55,14.81C243.67,553.48,237.04,549.65,235.31,543.2z M264.49,593.74c-4.73-4.73-4.73-12.38,0-17.11
        c4.72-4.72,12.38-4.72,17.11,0c4.72,4.72,4.72,12.38,0,17.1C276.87,598.46,269.22,598.46,264.49,593.74z M315.04,622.92
        c-6.45-1.73-10.28-8.36-8.55-14.81c1.73-6.45,8.36-10.28,14.81-8.55c6.45,1.73,10.28,8.36,8.55,14.81
        C328.12,620.82,321.49,624.65,315.04,622.92z M373.4,622.92c-6.45,1.73-13.09-2.1-14.81-8.55c-1.73-6.45,2.1-13.08,8.55-14.81
        c6.45-1.73,13.08,2.1,14.81,8.55C383.68,614.56,379.85,621.19,373.4,622.92z M423.94,593.74c-4.73,4.73-12.38,4.73-17.11,0
        c-4.72-4.72-4.72-12.38,0-17.11c4.72-4.72,12.38-4.72,17.1,0C428.66,581.36,428.66,589.01,423.94,593.74z M453.12,543.19
        c-1.73,6.45-8.36,10.28-14.81,8.55s-10.28-8.36-8.55-14.81c1.73-6.45,8.36-10.28,14.81-8.55
        C451.02,530.11,454.85,536.74,453.12,543.19z M453.12,484.83c1.73,6.45-2.1,13.09-8.55,14.81c-6.45,1.73-13.08-2.1-14.81-8.55
        c-1.73-6.45,2.1-13.08,8.55-14.81C444.76,474.55,451.39,478.38,453.12,484.83z M423.94,434.29c4.73,4.73,4.73,12.38,0,17.11
        c-4.72,4.72-12.38,4.72-17.11,0c-4.72-4.72-4.72-12.38,0-17.1C411.56,429.57,419.21,429.57,423.94,434.29z M373.39,405.11
        c6.45,1.73,10.28,8.36,8.55,14.81c-1.73,6.45-8.36,10.28-14.81,8.55c-6.45-1.73-10.28-8.36-8.55-14.81
        C360.31,407.21,366.94,403.38,373.39,405.11z M315.03,405.11c6.45-1.73,13.09,2.1,14.81,8.55c1.73,6.45-2.1,13.08-8.55,14.81
        c-6.45,1.73-13.08-2.1-14.81-8.55C304.75,413.47,308.58,406.84,315.03,405.11z M264.49,434.29c4.72-4.72,12.38-4.72,17.1,0
        c4.72,4.72,4.72,12.38,0,17.1c-4.72,4.72-12.38,4.72-17.1,0C259.77,446.67,259.77,439.01,264.49,434.29z M344.22,483.99
        c-16.58,0-30.03,13.44-30.03,30.03c0,16.58,13.44,30.03,30.03,30.03s30.03-13.44,30.03-30.03
        C374.24,497.43,360.8,483.99,344.22,483.99z M344.22,544.54c-16.83,0-30.53-13.69-30.53-30.53s13.69-30.53,30.53-30.53
        s30.53,13.69,30.53,30.53S361.05,544.54,344.22,544.54z M344.22,484.49c-16.28,0-29.53,13.25-29.53,29.53s13.25,29.53,29.53,29.53
        c16.28,0,29.53-13.25,29.53-29.53S360.5,484.49,344.22,484.49z"
      />
    </>
  );
}

export function Outdoor() {
  return (
    <>
      <path
        d="M12,74.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,74.35,12,74.35z M18,74.35c0.55,0,1,0.45,1,1s-0.45,1-1,1
      	s-1-0.45-1-1S17.45,74.35,18,74.35z M15,77.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S14.45,77.35,15,77.35z M21,77.35
      	c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S20.45,77.35,21,77.35z M24,74.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1
      	S23.45,74.35,24,74.35z M30,74.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S29.45,74.35,30,74.35z M27,77.35c0.55,0,1,0.45,1,1
      	s-0.45,1-1,1s-1-0.45-1-1S26.45,77.35,27,77.35z M33,77.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S32.45,77.35,33,77.35z
      	 M36,74.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S35.45,74.35,36,74.35z M42,74.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1
      	S41.45,74.35,42,74.35z M39,77.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S38.45,77.35,39,77.35z M45,77.35c0.55,0,1,0.45,1,1
      	s-0.45,1-1,1s-1-0.45-1-1S44.45,77.35,45,77.35z M48,74.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S47.45,74.35,48,74.35z
      	 M58,73.35v-34h-2v5h-1v-2h-8h-2h-6h-2h-6h-2h-6h-2h-6h-2H5v2H4v-5H2v34H0v6h6v-6H4v-15h1v2h8h2h6h2h6h2h6h2h6h2h8v-2h1v15h-2v6h6
      	v-6H58z M4,77.35H2v-2h2V77.35z M53,44.35v6h-6v-6H53z M45,44.35v6h-6v-6H45z M37,44.35v6h-6v-6H37z M29,44.35v6h-6v-6H29z
      	 M21,44.35v6h-6v-6H21z M7,44.35h6v6H7V44.35z M4,56.35v-10h1v4v2v4H4z M7,58.35v-6h6v6H7z M15,58.35v-6h6v6H15z M23,58.35v-6h6v6
      	H23z M31,58.35v-6h6v6H31z M39,58.35v-6h6v6H39z M53,58.35h-6v-6h6V58.35z M55,56.35v-4v-2v-4h1v10H55z M58,77.35h-2v-2h2V77.35z"
      />
      <path
        className="yellow"
        d="M19.68,14.3c-0.64-0.17-1.3,0.21-1.47,0.85c-0.17,0.64,0.21,1.3,0.85,1.47c0.64,0.17,1.3-0.21,1.47-0.85
      	C20.7,15.13,20.32,14.47,19.68,14.3z M26.73,9.56c0.64-0.17,1.02-0.83,0.85-1.47c-0.17-0.64-0.83-1.02-1.47-0.85
      	c-0.64,0.17-1.02,0.83-0.85,1.47C25.44,9.36,26.09,9.74,26.73,9.56z M22.8,11.83c0.47-0.47,0.47-1.23,0-1.69
      	c-0.47-0.47-1.23-0.47-1.69,0c-0.47,0.47-0.47,1.23,0,1.69C21.57,12.3,22.33,12.3,22.8,11.83z M19.06,19.46
      	c-0.64,0.17-1.02,0.83-0.85,1.47c0.17,0.64,0.83,1.02,1.47,0.85c0.64-0.17,1.02-0.83,0.85-1.47C20.36,19.67,19.7,19.29,19.06,19.46z
      	 M31.28,9.56c0.64,0.17,1.3-0.21,1.47-0.85c0.17-0.64-0.21-1.3-0.85-1.47c-0.64-0.17-1.3,0.21-1.47,0.85
      	C30.26,8.74,30.64,9.39,31.28,9.56z M38.95,16.61c0.64-0.17,1.02-0.83,0.85-1.47c-0.17-0.64-0.83-1.02-1.47-0.85
      	c-0.64,0.17-1.02,0.83-0.85,1.47C37.65,16.41,38.31,16.79,38.95,16.61z M36.9,11.83c0.47-0.47,0.47-1.23,0-1.69
      	c-0.47-0.47-1.23-0.47-1.69,0c-0.47,0.47-0.47,1.23,0,1.69C35.68,12.3,36.43,12.3,36.9,11.83z M21.11,24.24
      	c-0.47,0.47-0.47,1.23,0,1.69c0.47,0.47,1.23,0.47,1.69,0c0.47-0.47,0.47-1.23,0-1.69C22.33,23.77,21.57,23.77,21.11,24.24z
      	 M35.21,24.24c-0.47,0.47-0.47,1.23,0,1.69c0.47,0.47,1.23,0.47,1.69,0c0.47-0.47,0.47-1.23,0-1.69
      	C36.43,23.77,35.68,23.77,35.21,24.24z M26.73,26.51c-0.64-0.17-1.3,0.21-1.47,0.85c-0.17,0.64,0.21,1.3,0.85,1.47
      	c0.64,0.17,1.3-0.21,1.47-0.85C27.75,27.34,27.37,26.68,26.73,26.51z M38.95,19.46c-0.64-0.17-1.3,0.21-1.47,0.85
      	c-0.17,0.64,0.21,1.3,0.85,1.47c0.64,0.17,1.3-0.21,1.47-0.85C39.96,20.29,39.59,19.63,38.95,19.46z M34.09,12.95
      	c-2.81-2.81-7.36-2.81-10.17,0c-2.81,2.81-2.81,7.36,0,10.17s7.36,2.81,10.17,0S36.9,15.76,34.09,12.95z M29,21.06
      	c-1.67,0-3.02-1.36-3.02-3.02c0-1.67,1.36-3.02,3.02-3.02s3.02,1.36,3.02,3.02C32.03,19.71,30.67,21.06,29,21.06z M31.28,26.51
      	c-0.64,0.17-1.02,0.83-0.85,1.47c0.17,0.64,0.83,1.02,1.47,0.85c0.64-0.17,1.02-0.83,0.85-1.47C32.57,26.72,31.91,26.34,31.28,26.51
      	z"
      />
    </>
  );
}

export function Indoor() {
  return (
    <>
      <path
        className="white"
        d="M12,74.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,74.35,12,74.35z M18,74.35c0.55,0,1,0.45,1,1s-0.45,1-1,1
        s-1-0.45-1-1S17.45,74.35,18,74.35z M15,77.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S14.45,77.35,15,77.35z M21,77.35
        c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S20.45,77.35,21,77.35z M24,74.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1
        S23.45,74.35,24,74.35z M30,74.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S29.45,74.35,30,74.35z M27,77.35c0.55,0,1,0.45,1,1
        s-0.45,1-1,1s-1-0.45-1-1S26.45,77.35,27,77.35z M33,77.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S32.45,77.35,33,77.35z
         M36,74.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S35.45,74.35,36,74.35z M42,74.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1
        S41.45,74.35,42,74.35z M39,77.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S38.45,77.35,39,77.35z M45,77.35c0.55,0,1,0.45,1,1
        s-0.45,1-1,1s-1-0.45-1-1S44.45,77.35,45,77.35z M48,74.35c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S47.45,74.35,48,74.35z
         M58,73.35v-34h-2v5h-1v-2h-8h-2h-6h-2h-6h-2h-6h-2h-6h-2H5v2H4v-5H2v34H0v6h6v-6H4v-15h1v2h8h2h6h2h6h2h6h2h6h2h8v-2h1v15h-2v6h6
        v-6H58z M4,77.35H2v-2h2V77.35z M53,44.35v6h-6v-6H53z M45,44.35v6h-6v-6H45z M37,44.35v6h-6v-6H37z M29,44.35v6h-6v-6H29z
         M21,44.35v6h-6v-6H21z M7,44.35h6v6H7V44.35z M4,56.35v-10h1v4v2v4H4z M7,58.35v-6h6v6H7z M15,58.35v-6h6v6H15z M23,58.35v-6h6v6
        H23z M31,58.35v-6h6v6H31z M39,58.35v-6h6v6H39z M53,58.35h-6v-6h6V58.35z M55,56.35v-4v-2v-4h1v10H55z M58,77.35h-2v-2h2V77.35z"
      />
      <path
        className="yellow"
        d="M34.29,13.4c-0.65-1.07-1.21-1.99-1.21-3.24V8.53c0-1.7-1.38-3.08-3.08-3.08s-3.08,1.38-3.08,3.08v1.63
        c0,1.25-0.56,2.17-1.21,3.24c-0.64,1.05-1.37,2.25-1.37,3.78c0,3.12,2.54,5.66,5.66,5.66s5.66-2.54,5.66-5.66
        C35.66,15.65,34.93,14.45,34.29,13.4z M27.3,14.37c0.59-0.97,1.2-1.98,1.41-3.28h2.58c0.21,1.3,0.82,2.3,1.41,3.28
        c0.59,0.97,1.1,1.8,1.1,2.81c0,2.09-1.7,3.8-3.8,3.8c-2.09,0-3.8-1.7-3.8-3.8C26.2,16.17,26.71,15.34,27.3,14.37L27.3,14.37z
         M31.22,9.23h-2.44v-0.7c0-0.67,0.55-1.22,1.22-1.22s1.22,0.55,1.22,1.22V9.23z M40.69,17.18c0,0.47-0.38,0.86-0.86,0.86h-3.26
        v-1.72h3.26C40.3,16.32,40.69,16.7,40.69,17.18z M23.43,18.04h-3.26c-0.47,0-0.86-0.38-0.86-0.86s0.38-0.86,0.86-0.86h3.26V18.04z
         M35.25,13.14l-1.21-1.21l2.3-2.3c0.32-0.32,0.89-0.33,1.21,0c0.16,0.16,0.25,0.38,0.25,0.61c0,0.23-0.09,0.45-0.25,0.61
        L35.25,13.14z M24.75,21.22l1.21,1.21l-2.3,2.3c-0.16,0.16-0.38,0.25-0.61,0.25c-0.23,0-0.45-0.09-0.61-0.25
        c-0.33-0.33-0.33-0.88,0-1.21L24.75,21.22z M29.14,23.75h1.72v3.26c0,0.47-0.38,0.86-0.86,0.86s-0.86-0.38-0.86-0.86V23.75z
         M37.56,23.52c0.33,0.34,0.33,0.88,0,1.21l0,0c-0.16,0.16-0.38,0.25-0.61,0.25c-0.23,0-0.45-0.09-0.61-0.25l-2.3-2.3l1.21-1.21
        L37.56,23.52z M22.44,10.84c-0.16-0.16-0.25-0.38-0.25-0.61c0-0.23,0.09-0.44,0.25-0.61c0.16-0.16,0.38-0.25,0.61-0.25c0,0,0,0,0,0
        c0.23,0,0.44,0.09,0.61,0.25l2.3,2.3l-1.21,1.21L22.44,10.84z"
      />
    </>
  );
}

export function Beer() {
  return (
    <>
      <path d="M28.43,23.02v-10.7l1,0.11c0.55,0,1-0.45,1-1v-6c0-0.55-0.45-1-1-1h-12c-0.55,0-1,0.45-1,1v6c0,0.55,0.45,1,1,1h1v10.59
      	l-2.71,2.71c-1.23,1.23-3.29,3.29-3.29,5.71v30c0,1.66,1.34,3,3,3h16c1.66,0,3-1.34,3-3v-30c0-2.41-2.06-4.48-3.29-5.71L28.43,23.02
      	z M18.43,6.43h2v2h2v-2h2v2h2v-2h2v4h-10V6.43z M20.43,14.43v-2h6v2H20.43z M26.43,16.43v6h-6v-6H26.43z M32.43,61.43
      	c0,0.55-0.45,1-1,1h-1v-2h-2v2h-2v-2h-2v2h-2v-2h-2v2h-2v-2h-2v2h-1c-0.55,0-1-0.45-1-1v-3h18V61.43z M32.43,56.43h-18v-8h2.24
      	c1.11,3.52,3.86,6,6.76,6s5.66-2.48,6.76-6h2.24V56.43z M32.43,46.43h-3c-0.46,0-0.87,0.32-0.97,0.77c-0.7,2.98-2.86,5.23-5.03,5.23
      	s-4.32-2.25-5.03-5.23c-0.11-0.45-0.51-0.77-0.97-0.77h-3v-4h3c0.46,0,0.87-0.32,0.97-0.77c0.7-2.98,2.86-5.23,5.03-5.23
      	s4.32,2.25,5.03,5.23c0.11,0.45,0.51,0.77,0.97,0.77h3V46.43z M32.43,40.43H30.2c-1.11-3.52-3.85-6-6.76-6s-5.66,2.48-6.76,6h-2.24
      	v-8h18V40.43z M14.65,30.43c0.31-0.73,0.74-1.41,1.27-2h6.51v-2h-4.59l2-2h7.17l2.71,2.71c1.03,0.93,1.88,2.05,2.49,3.29H14.65z
      	 M52.43,23.02V12.43h1c0.55,0,1-0.45,1-1v-6c0-0.55-0.45-1-1-1h-12c-0.55,0-1,0.45-1,1v6c0,0.55,0.45,1,1,1h1v10.59l-2.71,2.71
      	c-1.23,1.23-3.29,3.29-3.29,5.71v30c0,1.66,1.34,3,3,3h16c1.66,0,3-1.34,3-3v-30c0-2.41-2.06-4.48-3.29-5.71L52.43,23.02z
      	 M42.43,6.43h2v2h2v-2h2v2h2v-2h2v4h-10V6.43z M44.43,14.43v-2h6v2H44.43z M50.43,16.43v6h-6v-6H50.43z M56.43,61.43
      	c0,0.55-0.45,1-1,1h-1v-2h-2v2h-2v-2h-2v2h-2v-2h-2v2h-2v-2h-2v2h-1c-0.55,0-1-0.45-1-1v-3h18V61.43z M56.43,56.43h-18v-10h2.34
      	c1.96,3.68,6.53,5.07,10.2,3.12c1.33-0.71,2.41-1.79,3.12-3.12h2.34V56.43z M56.43,44.43h-3c-0.42,0-0.8,0.27-0.94,0.67
      	c-1.19,2.79-4.42,4.09-7.22,2.9c-1.3-0.56-2.34-1.59-2.9-2.9c-0.14-0.4-0.52-0.67-0.94-0.67h-3v-2h3c0.42,0,0.8-0.27,0.94-0.67
      	c1.19-2.79,4.42-4.09,7.22-2.9c1.3,0.56,2.34,1.59,2.9,2.9c0.14,0.4,0.52,0.67,0.94,0.67h3V44.43z M56.43,40.43h-2.34
      	c-1.96-3.68-6.53-5.07-10.2-3.12c-1.33,0.71-2.41,1.79-3.12,3.12h-2.34v-4h3c0.55,0,1-0.45,1-1c0-1.36,2.23-3,5-3s5,1.64,5,3
      	c0,0.55,0.45,1,1,1h3V40.43z M56.43,34.43h-2.15c-0.69-2.22-3.59-4-6.85-4s-6.16,1.78-6.85,4h-2.15v-3c0-1.59,1.76-3.35,2.71-4.29
      	l2.71-2.71h7.17l2.71,2.71c0.95,0.95,2.71,2.71,2.71,4.29V34.43z M21.43,39.43h4v2h-4V39.43z M45.43,40.43h4v2h-4V40.43z
      	 M21.43,47.43h4v2h-4V47.43z M19.43,43.43h8v2h-8V43.43z M44.43,44.43h6v2h-6V44.43z M50.43,52.43h2v2h-2V52.43z M52.43,50.43h2v2
      	h-2V50.43z M24.43,26.43h2v2h-2V26.43z"
      />
    </>
  );
}

export function Birthday() {
  return (
    <>
      <path d="M64.74,31c-2.63-2.9-6.36-4.63-10.3-4.63H51.4v-5.74c0-2.14-1.04-4.01-2.63-5.19c1.18-1.11,1.87-2.77,1.87-4.49
      	c0-2.7-2.14-5.26-3.6-6.98l-0.28-0.28c-0.97-1.18-2.9-1.18-3.87,0L42.55,4.1c-1.38,1.66-3.53,4.15-3.53,6.91
      	c0,1.8,0.76,3.39,1.87,4.49c-1.59,1.18-2.63,3.04-2.63,5.19v5.74h-5.95V20.7c0-2.14-1.04-4.01-2.63-5.19
      	c1.18-1.11,1.87-2.77,1.87-4.49c0-2.7-2.14-5.26-3.6-6.98l-0.14-0.41c-0.97-1.18-2.9-1.18-3.87,0L23.6,4.03
      	c-1.38,1.66-3.53,4.15-3.53,6.91c0,1.8,0.76,3.39,1.87,4.49c-1.59,1.18-2.63,3.04-2.63,5.19v5.74h-3.04
      	c-3.94,0-7.68,1.66-10.23,4.56c-2.56,2.97-3.8,6.85-3.39,10.79c0.28,2.7,1.73,5.12,3.94,6.71c0.55,0.41,1.18,0.69,1.8,0.83
      	l1.66,12.65c0.35,2.9,2.9,5.12,5.81,5.12h39.07c2.97,0,5.46-2.21,5.81-5.12l1.66-12.65c0.62-0.21,1.24-0.48,1.8-0.9
      	c2.21-1.52,3.66-3.94,3.94-6.64C68.61,37.85,67.37,33.91,64.74,31z M44.97,5.97c1.11,1.31,2.77,3.32,2.77,4.91
      	c0,1.73-1.24,3.18-2.77,3.18s-2.77-1.45-2.77-3.18C42.13,9.29,43.86,7.28,44.97,5.97z M41.44,20.63c0-1.94,1.59-3.53,3.53-3.53
      	s3.53,1.59,3.53,3.53v5.74h-7.05V20.63z M25.88,5.97c1.11,1.31,2.77,3.32,2.77,4.91c0,1.73-1.24,3.18-2.77,3.18
      	s-2.77-1.45-2.77-3.18C23.11,9.29,24.77,7.28,25.88,5.97z M22.35,20.63c0-1.94,1.59-3.53,3.53-3.53s3.53,1.59,3.53,3.53v5.74h-7.05
      	V20.63z M57.76,61.5c-0.21,1.45-1.38,2.49-2.84,2.49h-39c-1.45,0-2.63-1.04-2.84-2.49l-1.52-12.17c0.83-0.21,1.59-0.48,2.28-0.97
      	l2.9-2.07c1.11-0.76,2.63-0.76,3.73,0l2.9,2.07c1.11,0.76,2.35,1.11,3.6,1.11c1.24,0,2.56-0.41,3.6-1.18l2.9-2.07
      	c1.11-0.83,2.56-0.83,3.73,0l2.9,2.01c2.14,1.52,5.12,1.52,7.26,0l2.9-2.07c1.11-0.76,2.63-0.76,3.73,0l2.9,2.07
      	c0.69,0.48,1.52,0.83,2.28,0.97L57.76,61.5z M65.16,41.37c-0.21,1.8-1.18,3.46-2.7,4.49l0,0c-1.11,0.76-2.63,0.76-3.73,0l-2.9-2.01
      	c-2.14-1.52-5.05-1.52-7.26,0l-2.9,2.07c-1.11,0.83-2.63,0.83-3.8,0l-2.9-2.07c-2.14-1.52-5.05-1.52-7.19,0l-2.9,2.07
      	c-1.11,0.83-2.63,0.83-3.73,0l-2.84-2.07c-2.14-1.52-5.05-1.52-7.26,0l-2.9,2.07c-1.11,0.83-2.56,0.83-3.73,0
      	c-1.52-1.11-2.49-2.77-2.7-4.56c-0.35-3.04,0.62-6.09,2.7-8.37c2.01-2.28,4.98-3.6,8.02-3.6h38.17c3.04,0,6.02,1.31,8.02,3.6
      	C64.53,35.29,65.5,38.33,65.16,41.37z M11.84,37.43c0.95,0,1.73,0.77,1.73,1.73s-0.77,1.73-1.73,1.73s-1.73-0.77-1.73-1.73
      	S10.89,37.43,11.84,37.43z M21.59,32.31c0.95,0,1.73,0.77,1.73,1.73s-0.77,1.73-1.73,1.73c-0.95,0-1.73-0.77-1.73-1.73
      	S20.64,32.31,21.59,32.31z M27.4,38.33c0.95,0,1.73,0.77,1.73,1.73s-0.77,1.73-1.73,1.73s-1.73-0.77-1.73-1.73
      	S26.45,38.33,27.4,38.33z M36.05,33.08c0.95,0,1.73,0.77,1.73,1.73c0,0.95-0.77,1.73-1.73,1.73s-1.73-0.77-1.73-1.73
      	C34.32,33.85,35.09,33.08,36.05,33.08z M43.86,38.33c0.95,0,1.73,0.77,1.73,1.73s-0.77,1.73-1.73,1.73s-1.73-0.77-1.73-1.73
      	S42.9,38.33,43.86,38.33z M50.98,32.59c0.95,0,1.73,0.77,1.73,1.73s-0.77,1.73-1.73,1.73c-0.95,0-1.73-0.77-1.73-1.73
      	S50.03,32.59,50.98,32.59z M59.83,38.33c0.95,0,1.73,0.77,1.73,1.73s-0.77,1.73-1.73,1.73s-1.73-0.77-1.73-1.73
      	S58.88,38.33,59.83,38.33z"
      />
    </>
  );
}

export function Cam() {
  return (
    <>
      <path d="M44.93,21.43c-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5S46.86,21.43,44.93,21.43z M44.93,26.43
      	c-0.83,0-1.5-0.67-1.5-1.5s0.67-1.5,1.5-1.5s1.5,0.67,1.5,1.5S45.76,26.43,44.93,26.43z M60.43,20.43h-2c-1.65,0-3,1.35-3,3h-3v-2
      	c0-2.21-1.79-4-4-4h-22c-3.86,0-7,3.14-7,7v8c0,3.31,2.31,6.08,5.39,6.81l-8.39,4.52v-3.33c0-0.55-0.45-1-1-1h-7c-0.55,0-1,0.45-1,1
      	v14c0,0.55,0.45,1,1,1h7c0.55,0,1-0.45,1-1v-3.41l21.26-11.59h10.74c2.21,0,4-1.79,4-4v-2h3c0,1.65,1.35,3,3,3h2c1.65,0,3-1.35,3-3
      	v-10C63.43,21.78,62.09,20.43,60.43,20.43z M14.43,53.43h-5v-12h5v4v5V53.43z M16.43,48.75v-2.72l12.25-6.6h4.83L16.43,48.75z
      	 M50.43,35.43c0,1.1-0.9,2-2,2h-11h-9h-2c-2.76,0-5-2.24-5-5v-8c0-2.41,1.72-4.43,4-4.9v10.9h2v-11h21c1.1,0,2,0.9,2,2v3v8V35.43z
      	 M52.43,31.43v-6h3v6H52.43z M61.43,33.43c0,0.55-0.45,1-1,1h-2c-0.55,0-1-0.45-1-1v-1v-8v-1c0-0.55,0.45-1,1-1h2c0.55,0,1,0.45,1,1
      	V33.43z M25.43,32.43h2v2h-2V32.43z"
      />
    </>
  );
}

export function Child() {
  return (
    <>
      <path d="M67.43,35.43c0-8.27-5.83-15-13-15c-0.38,0-0.76,0.03-1.14,0.07c0.08-0.68,0.14-1.38,0.14-2.07c0-8.27-5.83-15-13-15
      	s-13,6.73-13,15c0,7.22,4.44,13.26,10.34,14.68l-0.92,1.38c-0.28,0.42-0.43,0.9-0.43,1.41c0,1.4,1.14,2.53,2.53,2.53h1.47v27h2
      	V41.19c1.63,4.51,5.11,7.9,9.34,8.92l-0.92,1.38c-0.28,0.42-0.43,0.9-0.43,1.41c0,1.4,1.14,2.53,2.53,2.53h0.47v10h2v-10h0.47
      	c1.4,0,2.53-1.14,2.53-2.53c0-0.5-0.15-0.99-0.43-1.41l-0.92-1.38C62.99,48.7,67.43,42.65,67.43,35.43z M29.43,18.43
      	c0-7.17,4.93-13,11-13s11,5.83,11,13c0,0.83-0.08,1.67-0.21,2.48c-4.46,1.32-8.01,5.29-9.27,10.39c-0.5,0.08-1.01,0.13-1.51,0.13
      	C34.37,31.43,29.43,25.6,29.43,18.43z M38.43,35.9c0-0.11,0.03-0.21,0.09-0.3l1.19-1.78c0.16-0.24,0.43-0.39,0.72-0.39
      	c0.38,0,0.76-0.02,1.14-0.06c-0.08,0.67-0.14,1.36-0.14,2.06c0,0.34,0.03,0.67,0.04,1h-2.51C38.67,36.43,38.43,36.19,38.43,35.9z
      	 M55.16,50.82l1.19,1.78c0.06,0.09,0.09,0.19,0.09,0.3c0,0.29-0.24,0.53-0.54,0.53h-2.93c-0.29,0-0.53-0.24-0.53-0.53
      	c0-0.11,0.03-0.21,0.09-0.3l1.19-1.78c0.16-0.24,0.43-0.39,0.72-0.39S55,50.58,55.16,50.82z M54.43,48.43c-6.06,0-11-5.83-11-13
      	s4.94-13,11-13s11,5.83,11,13S60.5,48.43,54.43,48.43z M46.14,31.15l1.89,0.65c1.13-3.26,3.64-5.37,6.4-5.37v-2
      	C50.81,24.43,47.55,27.07,46.14,31.15z M45.43,35.43h2c0-0.62,0.05-1.23,0.15-1.83l-1.97-0.32C45.49,33.99,45.43,34.71,45.43,35.43z
      	 M31.43,18.43h2c0-0.63,0.05-1.25,0.15-1.85l-1.97-0.33C31.49,16.97,31.43,17.7,31.43,18.43z M40.43,7.43
      	c-3.6,0-6.84,2.61-8.26,6.64l1.88,0.67c1.14-3.22,3.64-5.31,6.38-5.31V7.43z M14.43,40.43h2v2h-2V40.43z M22.43,40.43h2v2h-2V40.43z
      	 M24.33,46.88l-1.78-0.91c-0.03,0.06-0.81,1.46-3.11,1.46s-3.08-1.4-3.11-1.46l-1.78,0.91c0.05,0.1,1.32,2.55,4.89,2.55
      	S24.28,46.99,24.33,46.88z M25.56,52.77l-1.86-0.41c2.27-1.23,3.96-3.41,4.52-6c1.83-0.37,3.21-1.98,3.21-3.92
      	c0-1.03-0.41-1.97-1.05-2.68c0.18-0.48,2.19-6.09-0.65-10.22c-1.87-2.72-5.33-4.1-10.3-4.1s-8.43,1.38-10.3,4.1
      	C6.3,33.66,8.3,39.27,8.49,39.75c-0.65,0.71-1.05,1.65-1.05,2.68c0,1.94,1.38,3.55,3.21,3.92c0.57,2.59,2.25,4.77,4.53,6l-1.86,0.41
      	c-2.26,0.5-4.12,2.09-4.96,4.18l-2.92,6.26v2.22h28v-2.22L30.54,57C29.68,54.86,27.82,53.27,25.56,52.77z M17.43,53.2
      	c0.64,0.15,1.31,0.23,2,0.23s1.36-0.08,2-0.23v0.73c-0.35,0.21-1.02,0.5-2,0.5c-0.99,0-1.65-0.29-2-0.5V53.2z M28.43,44.16v-3.44
      	c0.59,0.35,1,0.99,1,1.72S29.03,43.81,28.43,44.16z M10.79,30.66c1.47-2.14,4.38-3.22,8.64-3.22s7.17,1.08,8.64,3.22
      	c1.92,2.78,0.96,6.7,0.57,7.99c-0.07-0.02-0.14-0.05-0.21-0.06v-2.14c0-1.73-1.25-5-6-5c-2.83,0-3.9,1.64-4.84,3.08
      	c-1.02,1.57-1.91,2.92-5.16,2.92h-2v1.14c-0.07,0.02-0.14,0.04-0.21,0.06C9.83,37.35,8.87,33.44,10.79,30.66z M9.43,42.43
      	c0-0.74,0.4-1.38,1-1.72v3.44C9.84,43.81,9.43,43.17,9.43,42.43z M12.43,44.43v-5c4.33,0,5.72-2.12,6.83-3.83
      	c0.85-1.31,1.42-2.17,3.17-2.17c3.83,0,3.99,2.7,4,3v8c0,3.86-3.14,7-7,7S12.43,48.29,12.43,44.43z M26.43,63.43v-5h-2v5h-10v-5h-2
      	v5h-4.9l2.65-5.69c0.61-1.53,1.94-2.67,3.56-3.02l1.69-0.38v0.5l0.29,0.29c0.13,0.13,1.36,1.29,3.71,1.29s3.58-1.16,3.71-1.29
      	l0.29-0.29v-0.5l1.69,0.38c1.61,0.36,2.94,1.49,3.58,3.08l2.63,5.64H26.43z"
      />
    </>
  );
}

export function Event() {
  return (
    <>
      <path d="M66.83,17.79c0-0.66-0.54-1.2-1.2-1.2H54.68c-0.66,0-1.2,0.54-1.2,1.2v4.72c0,0.09,0.01,0.19,0.03,0.27v5.13
      	c-0.26-0.07-0.52-0.11-0.8-0.11c-1.76,0-3.2,1.44-3.2,3.2c0,1.76,1.44,3.2,3.2,3.2c1.76,0,3.2-1.44,3.2-3.2
      	c0-0.18-0.02-0.35-0.05-0.52c0.03-0.11,0.05-0.22,0.05-0.33V23.7h8.5v4.21c-0.26-0.07-0.52-0.11-0.8-0.11c-1.76,0-3.2,1.44-3.2,3.2
      	c0,1.76,1.44,3.2,3.2,3.2s3.2-1.44,3.2-3.2c0-0.18-0.02-0.35-0.05-0.52c0.03-0.11,0.05-0.22,0.05-0.33v-7.53
      	c0-0.04,0.01-0.07,0.01-0.11V17.79z M55.88,18.99h8.55v2.32h-8.55V18.99z M52.72,31.8c-0.44,0-0.8-0.36-0.8-0.8s0.36-0.8,0.8-0.8
      	c0.44,0,0.8,0.36,0.8,0.8S53.16,31.8,52.72,31.8z M63.61,31.8c-0.44,0-0.8-0.36-0.8-0.8s0.36-0.8,0.8-0.8s0.8,0.36,0.8,0.8
      	S64.06,31.8,63.61,31.8z M11.85,29.18c0-0.39-0.08-0.76-0.21-1.11v-4.3l8.55-1.63v3.97c-0.27-0.08-0.56-0.13-0.86-0.13
      	c-1.76,0-3.2,1.44-3.2,3.2s1.44,3.2,3.2,3.2c1.76,0,3.2-1.44,3.2-3.2c0-0.13-0.02-0.26-0.04-0.39c0.06-0.14,0.09-0.3,0.09-0.46
      	v-7.64v-4.72c0-0.36-0.16-0.7-0.43-0.92c-0.28-0.23-0.64-0.32-0.99-0.25l-10.94,2.09c-0.56,0.11-0.97,0.6-0.97,1.18v4.72v3.26
      	C9.05,26,8.85,25.98,8.65,25.98c-1.76,0-3.2,1.44-3.2,3.2s1.44,3.2,3.2,3.2S11.85,30.94,11.85,29.18z M19.33,29.98
      	c-0.44,0-0.8-0.36-0.8-0.8s0.36-0.8,0.8-0.8s0.8,0.36,0.8,0.8S19.77,29.98,19.33,29.98z M11.64,19.05l8.55-1.63v2.28l-8.55,1.63
      	V19.05z M8.65,29.98c-0.44,0-0.8-0.36-0.8-0.8s0.36-0.8,0.8-0.8c0.3,0,0.55,0.17,0.68,0.41c0.02,0.04,0.04,0.08,0.06,0.12
      	c0.03,0.09,0.06,0.18,0.06,0.27C9.45,29.62,9.09,29.98,8.65,29.98z M54.16,47.19c-0.01-7.67-4.87-14.22-11.67-16.75v-4.31
      	c0-0.66-0.54-1.2-1.2-1.2h-3.73V7.7c0-0.66-0.54-1.2-1.2-1.2c-0.66,0-1.2,0.54-1.2,1.2v17.23h-3.88c-0.66,0-1.2,0.54-1.2,1.2v4.29
      	c-6.82,2.52-11.7,9.08-11.71,16.77c-0.02,0.09-0.04,0.19-0.04,0.29c0,0.12,0.02,0.23,0.06,0.34c0.32,9.58,8.22,17.28,17.88,17.28
      	s17.55-7.7,17.88-17.28c0.03-0.11,0.06-0.22,0.06-0.34C54.2,47.38,54.18,47.28,54.16,47.19z M49.63,55.02h-3.95
      	c0.56-1.93,0.92-4.06,1.01-6.33h4.99C51.47,50.98,50.74,53.12,49.63,55.02z M20.85,48.68h4.99c0.1,2.27,0.45,4.41,1.01,6.33H22.9
      	C21.79,53.12,21.06,50.98,20.85,48.68z M22.59,39.95h4.22c-0.55,1.93-0.89,4.07-0.97,6.33h-5.01
      	C20.96,44.01,21.57,41.86,22.59,39.95z M37.46,37.55v-4.8c1.86,0.54,3.54,2.29,4.77,4.8H37.46z M43.2,39.95
      	c0.62,1.9,1.01,4.05,1.11,6.33h-6.84v-6.33H43.2z M35.07,32.75v4.8H30.3C31.53,35.04,33.2,33.29,35.07,32.75z M35.07,39.95v6.33
      	h-6.84c0.1-2.28,0.49-4.44,1.11-6.33H35.07z M28.23,48.68h6.84v6.33h-5.68C28.75,53.12,28.34,50.97,28.23,48.68z M35.07,57.41v4.64
      	c-1.83-0.53-3.47-2.22-4.69-4.64H35.07z M37.46,62.06v-4.64h4.69C40.93,59.84,39.29,61.53,37.46,62.06z M37.46,55.02v-6.33h6.84
      	c-0.11,2.29-0.52,4.44-1.16,6.33H37.46z M46.7,46.28c-0.08-2.27-0.42-4.4-0.97-6.33h4.1c0.04,0,0.07-0.01,0.11-0.01
      	c1.02,1.91,1.64,4.06,1.78,6.35C51.71,46.28,46.7,46.28,46.7,46.28z M48.36,37.55H44.9c-0.8-1.92-1.83-3.54-3.04-4.77
      	C44.43,33.78,46.67,35.44,48.36,37.55z M32.49,27.33h7.6v2.41c-1.23-0.27-2.51-0.42-3.83-0.42c-1.3,0-2.56,0.14-3.78,0.41V27.33z
      	 M30.67,32.78c-1.2,1.23-2.24,2.85-3.04,4.77h-3.46C25.87,35.44,28.1,33.78,30.67,32.78z M24.63,57.41h3.07
      	c0.66,1.56,1.48,2.91,2.42,4.02C28,60.51,26.14,59.13,24.63,57.41z M42.41,61.43c0.94-1.11,1.76-2.46,2.42-4.02h3.07
      	C46.4,59.13,44.53,60.51,42.41,61.43z M16.79,38.39l-4.61-1.98c-0.61-0.26-1.31,0.02-1.58,0.63c-0.26,0.61,0.02,1.31,0.63,1.57
      	l4.61,1.98c0.15,0.07,0.31,0.1,0.47,0.1c0.47,0,0.91-0.27,1.1-0.73C17.68,39.36,17.39,38.65,16.79,38.39z M15.22,55.42l-4.61,1.98
      	c-0.61,0.26-0.89,0.97-0.63,1.57c0.19,0.45,0.64,0.73,1.1,0.73c0.16,0,0.32-0.03,0.47-0.1l4.61-1.98c0.61-0.26,0.89-0.97,0.63-1.57
      	C16.54,55.44,15.83,55.16,15.22,55.42z M10.69,49.21l5.01-0.01c0.66,0,1.2-0.54,1.2-1.2c0-0.66-0.54-1.2-1.2-1.2c0,0,0,0,0,0
      	l-5.01,0.01c-0.66,0-1.2,0.54-1.2,1.2C9.49,48.68,10.02,49.21,10.69,49.21C10.69,49.21,10.69,49.21,10.69,49.21z M56.71,40.69
      	c0.16,0,0.32-0.03,0.47-0.1l4.61-1.98c0.61-0.26,0.89-0.97,0.63-1.57c-0.26-0.61-0.96-0.89-1.58-0.63l-4.61,1.98
      	c-0.61,0.26-0.89,0.97-0.63,1.57C55.8,40.42,56.25,40.69,56.71,40.69z M60.81,57.39l-4.61-1.98c-0.61-0.26-1.31,0.02-1.58,0.63
      	c-0.26,0.61,0.02,1.31,0.63,1.57l4.61,1.98c0.15,0.07,0.31,0.1,0.47,0.1c0.47,0,0.91-0.27,1.1-0.73
      	C61.7,58.36,61.42,57.66,60.81,57.39z M62.34,46.81l-5.01-0.01c0,0,0,0,0,0c-0.66,0-1.2,0.53-1.2,1.2c0,0.66,0.53,1.2,1.2,1.2
      	l5.01,0.01c0,0,0,0,0,0c0.66,0,1.2-0.53,1.2-1.2C63.54,47.35,63,46.82,62.34,46.81z"
      />
    </>
  );
}

export function Fitness() {
  return (
    <>
      <path d="M35.36,13.74c-2.54,0-4.61,2.07-4.61,4.61s2.07,4.62,4.61,4.62s4.61-2.07,4.61-4.62S37.9,13.74,35.36,13.74z M35.36,21.27
        c-1.61,0-2.91-1.31-2.91-2.92c0-1.61,1.31-2.92,2.91-2.92c1.61,0,2.91,1.31,2.91,2.92C38.27,19.96,36.97,21.27,35.36,21.27z
         M65.88,62.8H63.7V10.52c0-1.4-1.14-2.55-2.54-2.55c-1.4,0-2.54,1.14-2.54,2.55v0.9h-8.74c-0.06-0.15-0.14-0.29-0.24-0.43
        c-0.46-0.62-1.22-0.99-2.04-0.99c-0.97,0-1.77,0.54-2.15,1.42h-20c-0.37-0.87-1.18-1.42-2.16-1.42c-0.82,0-1.59,0.37-2.04,0.99
        c-0.1,0.13-0.18,0.28-0.24,0.43h-8.74v-0.9c0-1.4-1.14-2.55-2.54-2.55s-2.54,1.14-2.54,2.55V62.8H4.98c-0.47,0-0.85,0.38-0.85,0.85
        c0,0.47,0.38,0.85,0.85,0.85h9.46c0.47,0,0.85-0.38,0.85-0.85c0-0.47-0.38-0.85-0.85-0.85h-2.19V13.11h8.68
        c0.01,0.03,0.01,0.06,0.02,0.1c0.83,2.84,1.78,5.61,2.68,7.79c1.7,4.14,3.93,8.46,4.38,9.34l2.19,27.31
        c0.04,0.44,0.4,0.78,0.84,0.78c0,0,0,0,0,0h0.19v0c0,0.47,0.38,0.85,0.85,0.85h6.77c0.47,0,0.85-0.38,0.85-0.85v0h0.19c0,0,0,0,0,0
        c0.44,0,0.81-0.34,0.84-0.78l2.04-25.1l0.07-2.23c0.47-0.91,2.68-5.2,4.38-9.33c1.22-2.97,2.2-6.15,2.71-7.88h8.67V62.8h-2.19
        c-0.47,0-0.85,0.38-0.85,0.85c0,0.47,0.38,0.85,0.85,0.85h9.46c0.47,0,0.85-0.38,0.85-0.85C66.73,63.18,66.35,62.8,65.88,62.8z
         M10.56,62.8h-1.7V10.52c0-0.47,0.38-0.85,0.85-0.85s0.85,0.38,0.85,0.85L10.56,62.8L10.56,62.8z M44.83,13.11
        c-1.63,4.51-4.56,10.32-7.07,10.32c-0.16,0-0.32,0.05-0.46,0.13c-0.01,0.01-0.85,0.53-1.87,0.53c-1.01,0-1.86-0.53-1.86-0.53
        c-0.14-0.08-0.3-0.13-0.46-0.13c-2.5,0-5.43-5.79-7.07-10.32H44.83z M22.41,12.47c0-0.48,0.39-0.86,0.86-0.86s0.86,0.39,0.86,0.86
        c0,0.48-0.38,0.87-0.86,0.87S22.41,12.95,22.41,12.47z M34.59,57.58l-1.65,0v-0.01c0-0.23-0.09-0.44-0.25-0.6
        c-0.16-0.16-0.37-0.25-0.6-0.25c0,0,0,0,0,0l-0.26,0l-1.36-16.77h4.12V57.58z M39.12,56.73l-0.26,0c0,0,0,0,0,0
        c-0.23,0-0.44,0.09-0.6,0.25c-0.16,0.16-0.25,0.38-0.25,0.6l-1.73,0V39.95h4.2L39.12,56.73z M45.66,20.35
        c-1.87,4.54-4.37,9.31-4.4,9.35c-0.06,0.11-0.09,0.24-0.1,0.37l-0.08,2.38l-0.47,5.8H30.33l-0.47-5.79l-0.16-2.42
        c-0.01-0.12-0.04-0.23-0.1-0.34c-0.03-0.05-2.53-4.81-4.4-9.35c-0.66-1.61-1.35-3.54-2.02-5.65c0.03,0,0.06,0.02,0.09,0.02
        c0.52,0,0.98-0.21,1.36-0.51c1.38,3.68,4.46,10.62,8.23,10.91c0.42,0.23,1.4,0.68,2.56,0.68c1.17,0,2.15-0.45,2.57-0.68
        c3.78-0.29,6.86-7.25,8.23-10.91c0.39,0.31,0.86,0.52,1.39,0.52c0.02,0,0.04-0.01,0.06-0.01C47.16,16.34,46.47,18.39,45.66,20.35z
         M47.62,13.34c-0.48,0-0.87-0.39-0.87-0.87c0-0.48,0.39-0.86,0.87-0.86c0.47,0,0.86,0.39,0.86,0.86
        C48.48,12.95,48.09,13.34,47.62,13.34z M62,62.8h-1.7V10.52c0-0.47,0.38-0.85,0.85-0.85c0.47,0,0.85,0.38,0.85,0.85L62,62.8L62,62.8
        z"
      />
    </>
  );
}

export function Jacuzzi() {
  return (
    <>
      <path d="M46.43,56.43h-22v4h-2v6h26v-6h-2V56.43z M26.43,58.43h18v2h-18V58.43z M46.43,64.43h-22v-2h22V64.43z M63.43,37.43h-18h-1
      	h-15v-4.98c0-2.22-1.49-4.2-3.63-4.81l-3.37-0.96v-0.79c0.98-0.88,1.67-2.08,1.91-3.44c1.97-1.52,3.09-3.69,3.09-6.02
      	c0-1.31-0.36-2.55-1-3.65v-0.35c0-4.41-3.59-8-8-8s-8,3.59-8,8v0.35c-0.64,1.09-1,2.33-1,3.65c0,2.33,1.13,4.5,3.09,6.02
      	c0.23,1.36,0.92,2.56,1.91,3.44v0.79l-3.37,0.96c-2.14,0.61-3.63,2.59-3.63,4.81v4.98h-3v6h2v20c0,1.65,1.35,3,3,3h11v-23h4v11h2
      	v-11h3v11h2v-11h4v11h2v-11h3v11h2v-11h2v9c0,1.65,1.35,3,3,3s3-1.35,3-3v-6c0-0.55,0.45-1,1-1s1,0.45,1,1v3c0,1.65,1.35,3,3,3
      	s3-1.35,3-3v-5c0-0.55,0.45-1,1-1s1,0.45,1,1v13c0,1.3,0.84,2.4,2,2.82v3.18c0,0.55-0.45,1-1,1h-3v-10h-2v10h-4v-7h-2v9h11
      	c1.65,0,3-1.35,3-3v-3.18c1.16-0.41,2-1.51,2-2.82v-17C66.43,38.78,65.09,37.43,63.43,37.43z M22.43,21.43c0,2.21-1.79,4-4,4
      	s-4-1.79-4-4v-2.89c1.03,0.03,2.63-0.13,4.41-1.01l0.3-0.13c1.37,0.67,2.51,0.92,3.29,1.01V21.43z M18.43,6.43
      	c2.5,0,4.64,1.53,5.54,3.71c-1.53-1.07-3.45-1.71-5.54-1.71s-4.01,0.64-5.54,1.71C13.79,7.97,15.94,6.43,18.43,6.43z M13.68,16.46
      	l-1.24-0.31v3.36c-0.65-0.92-1-1.98-1-3.08c0-3.31,3.14-6,7-6s7,2.69,7,6c0,1.11-0.36,2.17-1,3.09l-0.01-3.21l-1.11,0.13
      	c-0.03,0.01-3.14,0.31-7.16-3.71l-1.41,1.41c0.8,0.8,1.57,1.45,2.31,1.98C15.13,16.79,13.74,16.48,13.68,16.46z M9.43,32.45
      	c0-1.33,0.89-2.52,2.18-2.89l4.82-1.38v-1.1c0.63,0.22,1.3,0.35,2,0.35s1.37-0.13,2-0.35v1.1l4.82,1.38
      	c1.28,0.37,2.18,1.55,2.18,2.89v4.98h-3v-6h-12v6h-3V32.45z M22.43,37.43h-8v-4h8V37.43z M8.43,63.43v-20h4v21h-3
      	C8.88,64.43,8.43,63.99,8.43,63.43z M18.43,64.43h-4v-21h4V64.43z M6.43,41.43v-2h38v2H6.43z M64.43,57.43c0,0.55-0.45,1-1,1
      	s-1-0.45-1-1v-13c0-1.65-1.35-3-3-3s-3,1.35-3,3v5c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3c0-1.65-1.35-3-3-3s-3,1.35-3,3v6
      	c0,0.55-0.45,1-1,1s-1-0.45-1-1v-13h17c0.55,0,1,0.45,1,1V57.43z M60.43,32.43c0-1.24,0.6-1.82,1.66-2.75
      	c1.04-0.91,2.34-2.05,2.34-4.25s-1.3-3.34-2.34-4.25c-1.06-0.93-1.66-1.51-1.66-2.75c0-1.23,0.6-1.82,1.66-2.74
      	c1.04-0.91,2.34-2.05,2.34-4.25v-1h-2v1c0,1.23-0.6,1.82-1.66,2.75c-1.04,0.91-2.34,2.05-2.34,4.25c0,2.2,1.3,3.34,2.34,4.25
      	c1.06,0.93,1.66,1.51,1.66,2.75c0,1.24-0.6,1.82-1.66,2.75c-1.04,0.91-2.34,2.05-2.34,4.25v1h2V32.43z M48.43,26.43
      	c0-1.24,0.6-1.82,1.66-2.75c1.04-0.91,2.34-2.05,2.34-4.25s-1.3-3.34-2.34-4.25c-1.06-0.93-1.66-1.51-1.66-2.75
      	c0-1.24,0.6-1.82,1.66-2.74c1.04-0.91,2.34-2.05,2.34-4.25v-1h-2v1c0,1.24-0.6,1.82-1.66,2.75c-1.04,0.91-2.34,2.05-2.34,4.25
      	c0,2.2,1.3,3.34,2.34,4.25c1.06,0.93,1.66,1.51,1.66,2.75c0,1.24-0.6,1.82-1.66,2.75c-1.04,0.91-2.34,2.05-2.34,4.25v1h2V26.43z
      	 M36.43,32.43c0-1.24,0.6-1.82,1.66-2.75c1.04-0.91,2.34-2.05,2.34-4.25s-1.3-3.34-2.34-4.25c-1.06-0.93-1.66-1.51-1.66-2.75
      	c0-1.23,0.6-1.82,1.66-2.74c1.04-0.91,2.34-2.05,2.34-4.25v-1h-2v1c0,1.23-0.6,1.82-1.66,2.75c-1.04,0.91-2.34,2.05-2.34,4.25
      	c0,2.2,1.3,3.34,2.34,4.25c1.06,0.93,1.66,1.51,1.66,2.75c0,1.24-0.6,1.82-1.66,2.75c-1.04,0.91-2.34,2.05-2.34,4.25v1h2V32.43z"
      />
    </>
  );
}

export function Massage() {
  return (
    <>
      <path d="M66.44,44.45h-3.19c0.11-0.31,0.19-0.65,0.19-1c0-1.65-1.35-3-3-3h-4.12c-0.47-2.28-2.48-4-4.9-4h-3
      	c-1.12,0-2.14,0.38-2.97,1h-2.31c-0.07-0.11-0.14-0.22-0.23-0.32c0.3-0.44,0.48-0.98,0.51-1.55c0.01-0.05,0.03-0.09,0.03-0.14v-7
      	c0-0.05-0.02-0.09-0.03-0.14c-0.03-1.06-0.39-2.03-0.99-2.83v-8.03c0-3.86-3.14-7-7-7c-3.86,0-7,3.14-7,7c0,0,0,0,0,0v8.02
      	c-0.62,0.83-1.01,1.86-1.01,2.98v6.98c0,0.01,0,0.01,0,0.02c0,0.35,0.07,0.69,0.18,1h-2.19c-0.01,0-0.02,0.01-0.04,0.01
      	c-1.64,0.02-2.97,1.35-2.97,3v0.01H10.93c-1.93,0-3.5,1.57-3.5,3.5c0,0.53,0.13,1.04,0.35,1.49H4.43c-0.55,0-1,0.45-1,1v5
      	c0,0.55,0.45,1,1,1h3v5h-3c-0.55,0-1,0.45-1,1s0.45,1,1,1h26.02c0,0,0.01,0,0.01,0s0.01,0,0.01,0h4.98c0,0,0.01,0,0.01,0
      	s0.01,0,0.01,0h4.98c0,0,0.01,0,0.01,0s0.01,0,0.01,0h25.98c0.55,0,1-0.45,1-1s-0.45-1-1-1h-3v-5h3c0.55,0,1-0.45,1-1v-5
      	C67.44,44.9,66.99,44.45,66.44,44.45z M51.42,38.45c1.3,0,2.4,0.84,2.81,2h-1.03c-1.21-0.09-2.22-0.9-2.6-2H51.42z M48.42,38.45h0.1
      	c0.15,0.74,0.48,1.41,0.92,2H45.6C46.02,39.29,47.12,38.45,48.42,38.45z M42.42,39.45h1.42c-0.14,0.32-0.25,0.65-0.32,1h-3.09
      	c-0.55,0-1,0.45-1,1s0.45,1,1,1h3.98c0,0,0.01,0,0.01,0s0.01,0,0.01,0h8.78c0.08,0,0.15,0.02,0.23,0.02c0.04,0,0.08-0.02,0.12-0.02
      	h1.85c0,0,0.01,0,0.01,0s0.01,0,0.01,0h5.01c0.55,0,1,0.45,1,1c0,0.55-0.45,1-1,1H34.42v-4.88c0.01-0.04,0.02-0.08,0.02-0.12
      	c0-0.55,0.45-1,1-1c0.01,0,0.01,0,0.02,0h5.94c0.01,0,0.01,0,0.02,0C41.42,39,41.87,39.45,42.42,39.45z M39.45,35.45
      	c0-0.55,0.45-1,0.99-1c0.55,0,1,0.45,1,1s-0.45,0.99-1,0.99C39.89,36.45,39.45,36,39.45,35.45z M40.44,20.51v3.37
      	c-0.35-0.15-0.72-0.26-1.1-0.33C40.01,22.71,40.42,21.66,40.44,20.51z M35.44,12.46c2.41,0,4.43,1.72,4.9,4h-9.79
      	C31.01,14.17,33.02,12.46,35.44,12.46z M38.44,18.45v2c0,1.66-1.35,3-3,3s-3-1.35-3-3v-2C32.43,18.45,38.44,18.45,38.44,18.45z
      	 M30.44,20.53c0.02,1.14,0.43,2.18,1.1,3.02c-0.38,0.07-0.75,0.18-1.1,0.33V20.53z M29.43,28.45c0-1.65,1.34-3,3-3h6.01
      	c1.65,0,3,1.35,3,3c0,0.02,0.01,0.04,0.01,0.07v4.12c-0.32-0.11-0.65-0.19-1.01-0.19c-0.35,0-0.68,0.07-0.99,0.18v-3.18
      	c0-0.55-0.45-1-1-1s-1,0.45-1,1v5.99c0,0.35,0.07,0.69,0.18,1h-4.39c0.1-0.28,0.16-0.58,0.17-0.9c0-0.03,0.02-0.06,0.02-0.1v-5.99
      	c0-0.55-0.45-1-1-1c-0.55,0-1,0.45-1,1v3.19c-0.32-0.11-0.65-0.19-1.01-0.19c-0.35,0-0.68,0.07-0.99,0.18L29.43,28.45L29.43,28.45z
      	 M29.43,35.45L29.43,35.45c0.01-0.55,0.45-1,0.99-1c0.55,0,1,0.45,1,1s-0.45,0.99-1,0.99C29.88,36.45,29.44,36,29.43,35.45
      	C29.43,35.45,29.43,35.45,29.43,35.45z M24.42,39.45c0-0.55,0.45-1,1-1c0.01,0,0.01,0,0.02,0h7.19c-0.09,0.24-0.14,0.5-0.16,0.76
      	c-0.02,0.08-0.05,0.16-0.05,0.24v7h-8C24.42,46.45,24.42,39.45,24.42,39.45z M10.93,41.46h11.49v2.99H10.81
      	c-0.77-0.06-1.39-0.7-1.39-1.49C9.43,42.13,10.1,41.46,10.93,41.46z M16.43,56.45H9.43v-5h7.01V56.45z M29.45,56.45H18.43v-5h4.98
      	h0.01h6.03L29.45,56.45L29.45,56.45z M31.54,56.45c0.21-0.58,0.76-1,1.41-1s1.2,0.42,1.41,1H31.54z M34.45,53.8
      	c-0.46-0.22-0.96-0.35-1.5-0.35c-0.54,0-1.04,0.13-1.5,0.35v-2.35h3C34.45,51.45,34.45,53.8,34.45,53.8z M36.54,56.45
      	c0.21-0.58,0.76-1,1.41-1s1.2,0.42,1.41,1H36.54z M39.45,53.8c-0.46-0.22-0.96-0.35-1.5-0.35s-1.04,0.13-1.5,0.35v-2.35h3V53.8z
      	 M52.42,56.45H41.45v-5h10.97L52.42,56.45L52.42,56.45z M61.44,56.45h-7.02v-5h7.02V56.45z M65.44,49.45H30.48
      	c-0.01,0-0.02-0.01-0.02-0.01s-0.02,0.01-0.02,0.01h-7.01h-0.01H5.43v-3h5.39c0.04,0,0.08,0.01,0.12,0.01
      	c0.02,0,0.04-0.01,0.06-0.01h11.44v1c0,0.55,0.45,1,1,1h10c0.55,0,1-0.45,1-1v-1h31.02V49.45z"
      />
    </>
  );
}

export function Movie() {
  return (
    <>
      <path d="M57.43,61.98l0.47-9l-2-0.11l-0.42,8.05h-3.05v2h4C56.97,62.93,57.4,62.51,57.43,61.98z M9.43,23.93
      	c0.03,0.96,0.36,1.88,0.97,2.62c-0.57,0.75-0.86,1.68-0.81,2.62l1.2,13.84l1.99-0.17l-1.19-13.8c-0.03-0.66,0.27-1.29,0.81-1.67
      	c0.69-0.49,1.62-0.49,2.31,0c0.07,0.05,0.14,0.11,0.21,0.18c0.36,0.35,0.57,0.83,0.59,1.34l0.81,14.11h0.01l2-0.12L17.5,29.02V28.9
      	c0.01-0.61,0.31-1.18,0.81-1.53c0.69-0.49,1.62-0.49,2.31,0c0.52,0.37,0.82,0.98,0.81,1.61l0.12,4.1c-1.28,0.9-2.07,2.35-2.12,3.91
      	c0.07,1.67,0.96,3.19,2.37,4.08l0.06,1.89l2-0.06l-0.02-0.73c2.97,1.1,6.23,1.1,9.2,0l-0.02,0.73l2,0.06l0.06-1.89
      	c1.41-0.89,2.29-2.42,2.36-4.08c-0.06-1.56-0.84-3.01-2.12-3.91l0.12-4.13c0-0.63,0.3-1.22,0.81-1.58c0.88-0.64,2.11-0.44,2.75,0.44
      	c0.24,0.34,0.37,0.74,0.37,1.15l-0.82,13.91l2,0.12h0.01l0.81-13.91c0-0.02,0-0.11,0-0.17V28.9c0.01-0.61,0.31-1.18,0.81-1.54
      	c0.69-0.5,1.62-0.5,2.3,0.01c0.14,0.1,0.26,0.22,0.38,0.35c0.28,0.35,0.43,0.79,0.43,1.23V29l-1.2,13.84l1.99,0.17l1.2-13.93v-0.14
      	c0-0.87-0.29-1.72-0.82-2.41c0.04-0.06,0.1-0.11,0.14-0.17c0.02-0.02,0.03-0.04,0.05-0.06c0.29-0.42,0.51-0.89,0.64-1.38h10.1
      	l-0.95,17.95l2,0.11l0.95-18.05h1.05c0.55,0,1-0.45,1-1v-4c0-0.55-0.45-1-1-1h-9v-15c0-0.55-0.45-1-1-1h-4c-0.55,0-1,0.45-1,1v15h-2
      	c0-2.21-1.79-4-4-4c-0.37,0-0.74,0.05-1.09,0.15c-0.47-2.16-2.6-3.53-4.76-3.06c-0.63,0.14-1.23,0.43-1.72,0.85
      	c-1.14-1.89-3.6-2.51-5.49-1.37c-0.56,0.34-1.03,0.81-1.37,1.37c-1.69-1.42-4.21-1.2-5.64,0.49c-0.42,0.5-0.71,1.09-0.85,1.72
      	c-2.13-0.6-4.34,0.64-4.94,2.77c-0.19,0.68-0.2,1.39-0.02,2.07c-2.21-0.06-4.06,1.68-4.12,3.89C9.43,23.85,9.43,23.89,9.43,23.93z
      	 M23.43,28.95c0-0.63,0.3-1.22,0.81-1.58c0.36-0.26,0.79-0.39,1.23-0.38c0.41,0,0.82,0.13,1.15,0.38c0.51,0.37,0.81,0.95,0.81,1.58
      	v2.08c-1.34,0.08-2.67,0.39-3.91,0.91L23.43,28.95z M28.43,40.99c-3.79,0-7-1.83-7-4s3.21-4,7-4s7,1.83,7,4S32.23,40.99,28.43,40.99
      	z M29.43,31.03v-2.08c0-0.63,0.3-1.22,0.81-1.58c0.36-0.26,0.79-0.39,1.23-0.38c1.07,0.01,1.94,0.86,1.96,1.93l-0.09,3.02
      	C32.1,31.42,30.78,31.11,29.43,31.03z M59.43,22.93H47.29c-0.2-0.78-0.63-1.47-1.24-2h13.38V22.93z M47.43,4.93h2v14h-2V4.93z
      	 M13.43,21.93c0.39,0,0.77,0.12,1.1,0.34c0.44,0.29,1.03,0.19,1.35-0.24l0.1-0.14c0.04-0.06,0.08-0.12,0.13-0.17
      	c0.33-0.4,0.3-0.99-0.08-1.35c-0.39-0.38-0.6-0.89-0.6-1.43c0-1.1,0.88-2,1.98-2c0.55,0,1.08,0.22,1.46,0.62
      	c0.38,0.4,1.02,0.41,1.41,0.03c0.27-0.26,0.37-0.65,0.26-1.01c-0.07-0.21-0.11-0.42-0.12-0.64c0.02-1.1,0.92-1.99,2.03-1.97
      	c0.9,0.01,1.69,0.63,1.91,1.51c0.14,0.52,0.66,0.84,1.18,0.74c0.55-0.05,0.95-0.54,0.9-1.09c0-0.01,0-0.02,0-0.03
      	c0-0.01-0.02-0.15-0.02-0.16c-0.06-1.1,0.79-2.04,1.89-2.1c1.1-0.06,2.04,0.79,2.1,1.89c0,0.09,0,0.18,0,0.26
      	c0,0.01-0.01,0.1-0.01,0.11c-0.05,0.58,0.37,1.1,0.96,1.16c0.52,0.05,0.99-0.28,1.13-0.78c0.27-1.07,1.35-1.73,2.42-1.46
      	c0.89,0.22,1.51,1.01,1.52,1.92c-0.01,0.22-0.05,0.44-0.12,0.64c-0.16,0.53,0.14,1.09,0.67,1.25c0.36,0.11,0.75,0.01,1.01-0.26
      	c0.76-0.8,2.02-0.84,2.82-0.08c0.4,0.38,0.63,0.91,0.62,1.46c0,0.24-0.04,0.48-0.13,0.71c-0.1,0.27-0.27,0.52-0.48,0.73
      	c-0.37,0.36-0.41,0.95-0.08,1.35c0.04,0.05,0.08,0.11,0.13,0.17l0.1,0.14c0.32,0.43,0.91,0.53,1.35,0.23
      	c0.29-0.19,0.62-0.3,0.96-0.32c0.03,0,0.1,0,0.13-0.01c1.11,0.01,2.01,0.91,2,2.02c0,0.01,0,0.01,0,0.02
      	c-0.03,0.42-0.17,0.83-0.41,1.18c-0.01,0.02-0.02,0.03-0.03,0.05l0,0c-0.03,0.05-0.07,0.09-0.1,0.13c-1.56-0.69-3.39-0.28-4.52,1
      	c-1.43-1.64-3.92-1.8-5.56-0.37c-0.13,0.12-0.26,0.24-0.37,0.37c-0.76-0.88-1.88-1.37-3.04-1.34c-0.84,0-1.66,0.26-2.33,0.76
      	c-0.23,0.17-0.44,0.36-0.63,0.58c-0.76-0.88-1.88-1.37-3.04-1.34c-0.84,0-1.66,0.26-2.33,0.76c-0.23,0.17-0.44,0.36-0.63,0.58
      	c-0.19-0.21-0.4-0.41-0.63-0.58c-1.65-1.21-3.96-0.96-5.31,0.58c-0.06-0.07-0.11-0.14-0.17-0.21c-0.14-0.13-0.28-0.26-0.44-0.37
      	c-1.14-0.81-2.61-0.97-3.9-0.42c-0.33-0.39-0.52-0.88-0.55-1.4C11.43,22.82,12.33,21.93,13.43,21.93z M33.43,22.99h2
      	c0-2.21-1.79-4-4-4v2C32.54,20.99,33.43,21.88,33.43,22.99z M64.43,44.93h-58c-0.55,0-1,0.45-1,1v16c0,0.55,0.45,1,1,1h17
      	c0.27,0,0.52-0.11,0.71-0.29l2.29-2.29l2.29,2.29c0.19,0.19,0.44,0.29,0.71,0.29h20c0.4,0,0.77-0.24,0.92-0.62l4.08-9.78
      	c0.55-1.33,2.07-1.95,3.4-1.4c0.97,0.4,1.6,1.35,1.6,2.4v8.4c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1v-16
      	C65.43,45.37,64.99,44.93,64.43,44.93z M27.14,58.22c-0.39-0.39-1.02-0.39-1.41,0l-2.71,2.71H7.43v-14h38v14H29.85L27.14,58.22z
      	 M63.43,60.93h-2v-7.4c0-2.54-2.06-4.6-4.6-4.6c-1.86,0-3.53,1.12-4.25,2.83l-3.82,9.17h-1.33v-14h16V60.93z M42.43,48.93h-12
      	c-0.55,0-1,0.45-1,1v8c0,0.55,0.45,1,1,1h12c0.55,0,1-0.45,1-1v-8C43.43,49.37,42.99,48.93,42.43,48.93z M41.43,56.93h-10v-6h10
      	V56.93z M22.43,48.93h-12c-0.55,0-1,0.45-1,1v8c0,0.55,0.45,1,1,1h12c0.55,0,1-0.45,1-1v-8C23.43,49.37,22.99,48.93,22.43,48.93z
      	 M21.43,56.93h-10v-6h10V56.93z"
      />
    </>
  );
}

export function Sauna() {
  return (
    <>
      <path d="M64.81,21.5l-5-2c-0.37-0.15-0.8-0.06-1.08,0.22l-3,3c-0.19,0.19-0.29,0.44-0.29,0.71v1.28l-2.32,0.77
        c-0.3,0.1-0.53,0.33-0.63,0.63l-1,3c-0.07,0.21-0.07,0.43,0,0.63l0.59,1.76c-0.38,0.15-0.64,0.52-0.64,0.93v10.12l-6.32-12.47
        c-0.48-0.96-1.44-1.58-2.51-1.63c-0.05-0.01-0.11-0.01-0.16-0.01h-4v-2.03c0.97-0.73,1.65-1.78,1.9-2.97h0.1c1.66,0,3-1.34,3-3v-3
        c0.01-2.45-1.11-4.78-3.05-6.28c0.04-0.24,0.05-0.48,0.05-0.72c0-2.76-2.24-5-5-5s-5,2.24-5,5c0,0.24,0.02,0.48,0.05,0.72
        c-1.93,1.51-3.05,3.83-3.05,6.28v3c0,1.66,1.34,3,3,3h0.1c0.25,1.19,0.93,2.25,1.9,2.97v2.03h-4c-0.04,0-0.08,0-0.12,0.01
        c-1.16-0.04-2.24,0.6-2.77,1.64l-6.77,13.36h-3.34v-2c0-1.1-0.9-2-2-2h-3c-2.76,0-5,2.24-5,5v2c0,0.55,0.45,1,1,1h3v2h-3
        c-0.55,0-1,0.45-1,1v4c0,0.55,0.45,1,1,1h5v9c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1v-9h13v7c0.01,1.65,1.35,2.99,3,3
        c1.66,0,3-1.34,3-3v-7h4.03l3.84,3.73c1.18,1.16,3.08,1.14,4.24-0.05c0.97-0.99,1.13-2.51,0.4-3.68h2.47v9c0,0.55,0.45,1,1,1h4
        c0.55,0,1-0.45,1-1v-9h7c0.55,0,1-0.45,1-1v-32C65.43,22.02,65.18,21.66,64.81,21.5z M54.22,27.22l2.21-0.74l2.29,0.76l0.81,1.63
        l0,0l0.68,1.35l-1.2,1.2h-4.86l-0.67-2L54.22,27.22z M51.43,46.83v2.61h-7v-3c0-1.88-1.32-3.51-3.16-3.91l-0.78-3.88l0.53-0.79
        c0.28-0.41,0.49-0.86,0.62-1.34l4.89,9.65c0.74,1.47,2.53,2.07,4.01,1.33C50.88,47.33,51.18,47.1,51.43,46.83z M42.43,46.43v2.55
        l-4.69-4.55h2.69C41.54,44.43,42.43,45.33,42.43,46.43z M38.43,22.43c0,1.66-1.34,3-3,3c-1.66,0-3-1.34-3-3v-3h6V22.43z
         M36.43,27.33v1.1h-2v-1.1C35.09,27.47,35.77,27.47,36.43,27.33z M40.43,21.43v-2c0.55,0,1,0.45,1,1S40.99,21.43,40.43,21.43z
         M39.68,13.19c1.13,1.12,1.76,2.65,1.76,4.24v0.18c-0.32-0.12-0.66-0.18-1-0.18h-0.7l-4.31-3.81l0,0l-2.04-1.8
        c1.21-0.43,2.52-0.48,3.76-0.13c0.28,0.08,0.55,0.18,0.81,0.3c0.26,0.12,0.52,0.26,0.76,0.42C39.06,12.64,39.38,12.9,39.68,13.19z
         M35.43,7.43c1.49,0,2.76,1.09,2.97,2.57c-1.91-0.76-4.03-0.76-5.94,0C32.68,8.53,33.94,7.43,35.43,7.43z M30.43,21.43
        c-0.55,0-1-0.45-1-1s0.45-1,1-1V21.43z M31.43,17.43h-1c-0.34,0-0.68,0.07-1,0.18v-0.18c0-1.76,0.77-3.43,2.11-4.57l5.17,4.57H31.43
        z M14.43,45.43h4.08c0.02,0.11,0.04,0.21,0.07,0.31c0.16,0.5,0.46,0.94,0.85,1.29v2.4h-8v-4H14.43z M7.43,45.43v-1
        c0-1.66,1.34-3,3-3h3v2h-3c-0.55,0-1,0.45-1,1v1H7.43z M15.43,63.43h-2v-8h2V63.43z M30.43,53.43h-23v-2h23V53.43z M21.43,49.43
        v-1.62c1.13,0,2.16-0.64,2.68-1.65l5.04-9.95c0.13,0.58,0.36,1.13,0.69,1.63l0.53,0.8l-0.78,3.88c-1.84,0.39-3.16,2.02-3.16,3.9v3
        H21.43z M30.43,49.43h-2v-3c0-1.1,0.9-2,2-2V49.43z M29.45,32.43c-0.38,0-0.72,0.21-0.89,0.55l-6.23,12.28
        c-0.24,0.5-0.84,0.71-1.33,0.47c-0.5-0.24-0.71-0.84-0.47-1.33c0.01-0.01,0.01-0.03,0.02-0.04l6.78-13.38
        c0.17-0.33,0.51-0.54,0.89-0.54c0.04,0,0.08,0,0.11,0.01c0.1,0.01,0.19,0.01,0.28-0.01H42.3c0.04,0.01,0.09,0.01,0.13,0.01
        c0.38,0,0.72,0.2,0.89,0.54l6.78,13.38c0.25,0.49,0.05,1.09-0.44,1.34c-0.49,0.25-1.09,0.05-1.34-0.44L42.1,32.98
        c-0.17-0.34-0.52-0.55-0.89-0.55H29.45z M32.31,41.65c-0.17,0.06-0.34,0.15-0.49,0.25c-0.02,0.01-0.05,0.03-0.07,0.04l0.66-3.31
        c0.05-0.26,0-0.53-0.15-0.75l-0.76-1.15c-0.45-0.68-0.59-1.51-0.38-2.3h8.63c0.07,0.24,0.1,0.48,0.1,0.73
        c0,0.56-0.17,1.11-0.48,1.58l-0.76,1.14c-0.15,0.22-0.2,0.49-0.15,0.75l0.76,3.8h-3.54l-0.12-0.12c-0.05-0.05-0.1-0.08-0.15-0.12
        c-0.09-0.08-0.18-0.16-0.27-0.24c-0.03-0.02-0.05-0.03-0.08-0.05c-0.15-0.1-0.32-0.19-0.49-0.25l0,0c-0.18-0.08-0.37-0.13-0.56-0.17
        l-0.03-0.01c-0.35-0.07-0.71-0.07-1.05,0l-0.04,0.01C32.68,41.52,32.49,41.57,32.31,41.65L32.31,41.65z M34.43,62.43
        c0,0.55-0.45,1-1,1c-0.27,0-0.53-0.11-0.71-0.31c-0.19-0.18-0.29-0.43-0.29-0.69V47.64l2,1.94V62.43z M36.43,53.43v-1.91l1.97,1.91
        H36.43z M47.14,57.75c-0.39,0.39-1.02,0.39-1.41,0c0,0-0.01-0.01-0.01-0.01l-12.99-12.6c-0.28-0.28-0.37-0.7-0.22-1.07l0.01-0.03
        c0.05-0.1,0.11-0.2,0.19-0.28l0.03-0.02l0.03-0.03c0.08-0.08,0.18-0.14,0.28-0.19l0.02-0.01c0.11-0.04,0.22-0.06,0.33-0.06h0.08
        c0.11,0,0.22,0.02,0.33,0.06c0.01,0.01,0.03,0.01,0.04,0.02c0.1,0.05,0.19,0.11,0.27,0.19c0.02,0.02,0.03,0.03,0.05,0.04
        c0.06,0.06,0.1,0.12,0.14,0.19l0.04-0.02l12.79,12.41C47.53,56.72,47.53,57.36,47.14,57.75z M55.43,63.43h-2v-8h2V63.43z
         M63.43,53.43H47.02l-2.06-2h18.47V53.43z M63.43,49.43h-10v-4h10V49.43z M63.43,43.43h-10v-4h10V43.43z M63.43,37.43h-10v-4h10
        V37.43z M63.43,31.43h-1.59l0.29-0.29c0.3-0.3,0.38-0.77,0.19-1.15l-0.55-1.11l1.66-0.83V31.43z M63.43,25.82l-2.55,1.28l-0.55-1.11
        c-0.12-0.24-0.33-0.42-0.58-0.5l-2.32-0.77v-0.86l2.24-2.24l3.76,1.5V25.82z M10.43,26.43c2.75,0.01,4.99-2.22,5-4.97
        c0-1.58-0.74-3.06-2-4.01V8.43c0-1.66-1.34-3-3-3s-3,1.34-3,3v9.02c-0.53,0.4-0.98,0.9-1.32,1.47c-0.45,0.76-0.68,1.63-0.68,2.51
        C5.44,24.19,7.67,26.43,10.43,26.43z M7.84,19.94c0.27-0.45,0.65-0.83,1.1-1.09c0.31-0.18,0.5-0.51,0.5-0.86V8.43
        c0.04-0.55,0.52-0.96,1.07-0.92c0.49,0.04,0.88,0.43,0.92,0.92v9.55c0,0.36,0.19,0.69,0.5,0.86c0.93,0.53,1.5,1.51,1.5,2.58
        c0,1.66-1.34,3-3,3s-3-1.34-3-3C7.43,20.91,7.57,20.39,7.84,19.94z M15.43,7.43h2v2h-2V7.43z M15.43,11.43h2v2h-2V11.43z
         M15.43,15.43h2v2h-2V15.43z M10.43,19.93c0.83,0,1.5,0.67,1.5,1.5s-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5S9.6,19.93,10.43,19.93z
         M65.43,7.43c0-0.55-0.45-1-1-1c-0.55,0-1,0.45-1,1c-0.01,0.61-0.28,1.19-0.75,1.59c-1.61,1.53-1.68,4.07-0.15,5.68
        c0.05,0.05,0.1,0.1,0.15,0.15c0.47,0.39,0.74,0.97,0.75,1.58c0,0.55,0.45,1,1,1c0.55,0,1-0.45,1-1c0-1.1-0.46-2.15-1.25-2.91
        c-0.88-0.72-1-2.01-0.28-2.89c0.08-0.1,0.18-0.2,0.28-0.28C64.98,9.59,65.43,8.53,65.43,7.43z M58.43,16.43c0,0.55,0.45,1,1,1
        s1-0.45,1-1c0-1.1-0.46-2.15-1.25-2.91c-0.88-0.72-1-2.01-0.28-2.89c0.08-0.1,0.18-0.2,0.28-0.28c0.8-0.76,1.25-1.81,1.25-2.92
        c0-0.55-0.45-1-1-1s-1,0.45-1,1c-0.01,0.61-0.28,1.19-0.75,1.59c-1.61,1.53-1.68,4.07-0.15,5.68c0.05,0.05,0.1,0.1,0.15,0.15
        C58.15,15.24,58.43,15.82,58.43,16.43z M53.43,16.43c0,0.55,0.45,1,1,1s1-0.45,1-1c0-1.1-0.46-2.15-1.25-2.91
        c-0.88-0.72-1-2.01-0.28-2.89c0.08-0.1,0.18-0.2,0.28-0.28c0.8-0.76,1.25-1.81,1.25-2.92c0-0.55-0.45-1-1-1s-1,0.45-1,1
        c-0.01,0.61-0.28,1.19-0.75,1.59c-1.61,1.53-1.68,4.07-0.15,5.68c0.05,0.05,0.1,0.1,0.15,0.15C53.15,15.24,53.43,15.82,53.43,16.43z
         M16.43,37.43h2v2h-2V37.43z M16.43,33.43h2v2h-2V33.43z M12.43,35.43h2v2h-2V35.43z"
      />
    </>
  );
}

export function Shirt() {
  return (
    <>
      <path
        transform="translate(0,-988.36214)"
        d="M29.42,994.91c-0.05,0-0.1,0.01-0.14,0.01H17.45c0,0-0.01,0-0.01,0
      	c-5.52,0-10,4.48-10,10v15c0,0.55,0.45,1,1,1h9v29c0,0.55,0.45,1,1,1h34c0.55,0,1-0.45,1-1v-29h9c0.55,0,1-0.45,1-1v-15
      	c0-5.52-4.48-10-10-10H41.6c-0.11-0.02-0.22-0.02-0.33,0H29.59C29.53,994.92,29.47,994.91,29.42,994.91L29.42,994.91z M17.43,996.93
      	h11v1c0,3.86,3.14,7,7,7s7-3.14,7-7v-1h11c4.45,0,8,3.55,8,8v10h-8v-9c0.01-0.55-0.43-1.01-0.99-1.01
      	c-0.55-0.01-1.01,0.43-1.01,0.99c0,0.01,0,0.02,0,0.03v13.83c-0.02,0.11-0.02,0.22,0,0.33v28.84h-32v-28.83
      	c0.02-0.11,0.02-0.22,0-0.33v-13.84c0.01-0.55-0.43-1.01-0.99-1.01c-0.01,0-0.02,0-0.03,0c-0.55,0.01-0.99,0.46-0.98,1.01v9h-8v-10
      	C9.43,1000.48,12.98,996.93,17.43,996.93z M30.43,996.93h10v1c0,2.79-2.21,5-5,5s-5-2.21-5-5V996.93z M18.44,998.93
      	c-0.55-0.01-1.01,0.43-1.02,0.98c-0.01,0.55,0.43,1.01,0.98,1.02c0.01,0,0.02,0,0.03,0l6.41,0.03c0.41,1.34,1,2.59,1.83,3.66
      	c0.32,0.45,0.95,0.55,1.4,0.23c0.45-0.32,0.55-0.95,0.23-1.4c-0.02-0.02-0.03-0.04-0.05-0.06c-0.82-1.06-1.42-2.3-1.73-3.66
      	c-0.1-0.45-0.51-0.77-0.97-0.78L18.44,998.93L18.44,998.93z M52.43,998.93l-7.11,0.03c-0.46,0-0.86,0.32-0.97,0.78
      	c-0.31,1.36-0.91,2.6-1.73,3.66c-0.36,0.42-0.3,1.05,0.12,1.41c0.42,0.36,1.05,0.3,1.41-0.12c0.02-0.02,0.03-0.04,0.05-0.06
      	c0.83-1.07,1.42-2.33,1.83-3.66l6.41-0.03c0.55,0,1-0.44,1.01-0.99c0-0.55-0.44-1-0.99-1.01C52.44,998.93,52.44,998.93,52.43,998.93
      	L52.43,998.93z M42.42,1009.93c-0.26,0-0.51,0.11-0.69,0.29c0,0-0.43,0.43-1.1,0.88s-1.53,0.83-2.2,0.83c-0.55,0-1,0.45-1,1v4
      	c0,0.84,0.39,1.53,0.85,2.1c0.46,0.57,1.02,1.05,1.57,1.46c1.1,0.81,2.17,1.34,2.17,1.34c0.28,0.14,0.61,0.14,0.89,0
      	c0,0,1.06-0.53,2.13-1.35s2.38-1.89,2.38-3.55v-4c0-0.55-0.45-1-1-1c-0.67,0-1.53-0.39-2.2-0.83c-0.66-0.44-1.1-0.88-1.1-0.88
      	C42.95,1010.03,42.69,1009.93,42.42,1009.93L42.42,1009.93z M42.43,1012.2c0.25,0.21,0.28,0.28,0.7,0.56
      	c0.61,0.41,1.42,0.76,2.3,0.96v3.2c0,0.34-0.68,1.27-1.58,1.95c-0.68,0.52-1.08,0.71-1.38,0.87c-0.3-0.16-0.72-0.36-1.42-0.88
      	c-0.46-0.34-0.91-0.74-1.21-1.11c-0.3-0.37-0.41-0.68-0.41-0.84v-3.2c0.89-0.21,1.7-0.56,2.3-0.96
      	C42.15,1012.48,42.19,1012.41,42.43,1012.2z M9.43,1016.93h8v2h-8V1016.93z M53.43,1016.93h8v2h-8V1016.93z"
      />
    </>
  );
}

export function Shop() {
  return (
    <>
      <path d="M43.43,36.3c-1.65,0-3,1.33-3,2.99c0,1.27,0.8,2.41,2,2.83v3.18c0,4.42-3.58,8-8,8s-8-3.58-8-8v-3.18
      	c1.56-0.55,2.38-2.27,1.83-3.83c-0.55-1.56-2.27-2.38-3.83-1.83c-1.56,0.55-2.38,2.27-1.83,3.83c0.3,0.85,0.97,1.53,1.83,1.83v3.18
      	c0,5.52,4.48,10,10,10s10-4.48,10-10v-3.18c1.56-0.55,2.38-2.26,1.83-3.82C45.84,37.1,44.7,36.3,43.43,36.3z M25.43,38.3
      	c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S24.88,38.3,25.43,38.3z M43.43,40.3c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1
      	S43.99,40.3,43.43,40.3z M59.43,30.3h-6.38c0.04-0.17,0.04-0.35-0.01-0.52l-1.5-4.48l3.25,3.42c0.38,0.4,1.01,0.42,1.41,0.04
      	c0.01-0.01,0.01-0.01,0.02-0.02l2.91-2.92c0.39-0.39,0.39-1.02,0-1.41l-8.76-8.73c-0.32-0.32-0.83-0.38-1.22-0.15l-6,3.61
      	c-0.42,0.25-0.59,0.77-0.42,1.23l3.94,9.94h-3.82c0.05-0.91,0.23-1.81,0.52-2.68l-1.89-0.64c-0.36,1.07-0.57,2.19-0.62,3.32h-4.52
      	c-0.18-0.98-0.62-1.9-1.27-2.65l-1.51,1.31c0.33,0.39,0.58,0.85,0.72,1.35h-6.88c0.07-0.14,0.1-0.29,0.1-0.44l-0.03-1.1h0.05
      	c1.77,0.18,3.55-0.18,5.12-1.01l2.27-1.21c0.49-0.26,0.67-0.87,0.41-1.35c-0.12-0.23-0.34-0.41-0.59-0.49l-7.72-2.35l-1.69-3.16
      	c-0.26-0.49-0.87-0.67-1.35-0.41l-5.29,2.82c-0.49,0.26-0.67,0.86-0.41,1.35l1.88,3.53l0,0l2.04,3.82h-4.75c-0.55,0-1,0.45-1,1
      	v10.38L14.6,39.3c-0.28-0.37-0.79-0.5-1.22-0.3l-2.95,1.39v-1.62c-0.01-2.36,1.33-4.52,3.45-5.57l-0.89-1.79
      	c-2.8,1.39-4.56,4.24-4.55,7.36v2.53h0.05L6.14,42.4c-0.42,0.2-0.66,0.66-0.55,1.12l2,9c0.07,0.3,0.26,0.55,0.54,0.68
      	c0.27,0.13,0.59,0.14,0.86,0.01l7.45-3.48V64.3c0,0.55,0.45,1,1,1h34c0.01,0,0.03-0.01,0.04-0.01c0.1,0,0.2-0.02,0.3-0.06
      	c0.02-0.01,0.05-0.01,0.07-0.02c0.02-0.01,0.03-0.01,0.04-0.01l3.55-1.78l3.55,1.78c0.49,0.25,1.09,0.05,1.34-0.45
      	c0.07-0.14,0.11-0.3,0.11-0.45v-33C60.43,30.75,59.99,30.3,59.43,30.3z M31.69,25.99c-1.22,0.65-2.6,0.93-3.98,0.79l-1.17-0.12
      	c-0.55-0.06-1.04,0.35-1.1,0.89c0,0.04-0.01,0.08,0,0.13l0.04,1.62l-1.22,0.65l-1.88-3.53l4.05-2.16l5.41,1.64L31.69,25.99z
      	 M24.02,21.01l0.94,1.76l-1.76,0.94l-1.76,0.94l-0.94-1.76L24.02,21.01z M49.52,17.64l7.5,7.48l-1.48,1.49l-5.65-5.93
      	c-0.38-0.4-1.01-0.42-1.41-0.04c-0.28,0.27-0.38,0.67-0.26,1.04l2.6,7.77l-2.08,0.64l-3.83-9.66L49.52,17.64z M7.71,43.87l5.79-2.71
      	l4.37,5.68l-1.59,0.74l-1.27-2.72l-1.81,0.85l1.27,2.72l-1.81,0.85l-1.27-2.72l-1.81,0.85l1.27,2.72l-1.59,0.74L7.71,43.87z
      	 M18.43,48.79l1.42-0.67c0.5-0.23,0.72-0.83,0.48-1.33c-0.03-0.07-0.07-0.13-0.11-0.19l-1.79-2.33V32.3h32v31h-32V48.79z
      	 M58.43,62.69l-2.55-1.28c-0.28-0.14-0.61-0.14-0.89,0l-2.55,1.28V32.3h6V62.69z M6.43,20.3h10c0.55,0,1-0.45,1-1v-3
      	c0-2.14-1.15-4.12-3-5.19V8.3c0-1.66-1.34-3-3-3s-3,1.34-3,3v2.81c-1.85,1.07-3,3.05-3,5.19v3C5.43,19.86,5.88,20.3,6.43,20.3z
      	 M10.43,8.3c0-0.55,0.45-1,1-1s1,0.45,1,1v2.09c-0.66-0.12-1.34-0.12-2,0V8.3z M7.43,16.3c0-0.97,0.36-1.9,1-2.62v2.62h2v-3.86
      	c0.33-0.09,0.66-0.14,1-0.14c0.34,0,0.67,0.05,1,0.14v3.86h2v-2.62c0.64,0.72,1,1.65,1,2.62v2h-8V16.3z M25.63,15.33
      	c0.28,0.48,0.89,0.64,1.37,0.37c0.02-0.01,0.04-0.03,0.06-0.04l1.46-1l1.27,4.43c0.15,0.53,0.71,0.84,1.24,0.69l7.69-2.21
      	c0.53-0.15,0.84-0.71,0.69-1.24c0,0,0,0,0,0l-1.27-4.44l1.77,0.08c0.28,0.01,0.55-0.09,0.75-0.29c0.2-0.2,0.31-0.47,0.3-0.74
      	l-0.14-4.12c-0.02-0.5-0.4-0.91-0.9-0.96l-5.36-0.54c-0.33-0.03-0.65,0.1-0.86,0.35l-0.61,0.72c-0.78,0.93-2.05,1.29-3.2,0.92
      	L28.99,7c-0.31-0.1-0.66-0.04-0.92,0.16l-4.25,3.3c-0.39,0.31-0.5,0.86-0.25,1.29L25.63,15.33z M28.89,9.07l0.39,0.12
      	c1.92,0.62,4.03,0.01,5.33-1.53l0.27-0.32l3.97,0.4l0.08,2.17l-2.09-0.09c-0.32-0.01-0.63,0.13-0.83,0.38
      	c-0.2,0.25-0.26,0.59-0.17,0.89l1.38,4.81l-5.77,1.65l-1.38-4.81c-0.09-0.31-0.32-0.55-0.62-0.66c-0.3-0.11-0.64-0.06-0.9,0.12
      	l-1.72,1.17l-1.09-1.88L28.89,9.07z M64.04,14l-2.82-1.04l0.35-0.94c0.97-2.59-0.34-5.47-2.93-6.44c-2.59-0.97-5.47,0.34-6.44,2.93
      	c-0.01,0.02-0.02,0.04-0.02,0.06l-0.69,1.88c-0.05,0.13-0.07,0.26-0.06,0.39c0.02,0.4,0.28,0.75,0.65,0.89l11.27,4.13
      	c0.53,0.16,1.09-0.14,1.24-0.67C64.74,14.72,64.5,14.21,64.04,14z M57.91,7.49c1.56,0.57,2.35,2.29,1.78,3.85c0,0,0,0,0,0
      	l-0.35,0.94l-5.63-2.07l0.34-0.94C54.63,7.72,56.35,6.92,57.91,7.49C57.91,7.49,57.91,7.49,57.91,7.49L57.91,7.49z M40.43,25.3v-2
      	c0-1.73-1.01-3.31-2.58-4.05l-0.85,1.81c0.87,0.41,1.42,1.28,1.42,2.24v2H40.43z M58.24,15.21l-1.79-0.9
      	c-0.55,1.1-1.39,2.03-2.41,2.7l1.1,1.67C56.46,17.82,57.53,16.62,58.24,15.21z M12.03,21.86l-1.8,0.88
      	c1.32,2.67,4.03,4.36,7.01,4.37c0.07,0,0.14,0,0.22,0l-0.05-2C15.12,25.18,13.02,23.91,12.03,21.86z M54.43,57.3h2v2h-2V57.3z
      	 M54.43,53.3h2v2h-2V53.3z M54.43,47.3h2v4h-2V47.3z"
      />
    </>
  );
}

export function Yoga() {
  return (
    <>
      <path d="M52.43,47.93h-5V37.19l6.24-11.58c0.5-0.92,0.76-1.97,0.76-3.02c0-1.97-0.93-3.85-2.49-5.05L41.26,9.31l-1.45-3.62
      	c-0.43-1.06-1.44-1.75-2.59-1.75c-0.68,0-1.3,0.26-1.79,0.67c-0.49-0.41-1.1-0.67-1.79-0.67c-1.15,0-2.16,0.69-2.59,1.75l-1.45,3.62
      	l-10.69,8.22c-1.56,1.2-2.49,3.09-2.49,5.05c0,1.05,0.26,2.1,0.76,3.02l6.24,11.58v10.75h-5c-4.96,0-9,4.04-9,9s4.04,9,9,9h34
      	c4.96,0,9-4.04,9-9S57.4,47.93,52.43,47.93z M37.22,5.93c0.32,0,0.61,0.2,0.73,0.5l1.65,4.13l11.12,8.55
      	c1.07,0.82,1.71,2.12,1.71,3.47c0,0.72-0.18,1.44-0.52,2.07l-2.08,3.86l-2.62-1.31c0.13-0.4,0.22-0.82,0.22-1.27v-2
      	c0-6.28-4.85-11.44-11-11.95V6.72C36.43,6.29,36.79,5.93,37.22,5.93z M35.43,33.93c-3.86,0-7-3.14-7-7v-2.59
      	c0-0.65,0.25-1.25,0.71-1.71c0.46-0.46,1.06-0.71,1.71-0.71c1.69,0,3.3-0.58,4.59-1.64c1.29,1.06,2.9,1.64,4.59,1.64
      	c0.65,0,1.25,0.25,1.71,0.71c0.46,0.45,0.71,1.06,0.71,1.71v2.59C42.43,30.79,39.29,33.93,35.43,33.93z M37.94,35.57
      	c-0.54,0.82-1.46,1.36-2.51,1.36s-1.97-0.54-2.51-1.36c0.8,0.23,1.64,0.36,2.51,0.36S37.14,35.8,37.94,35.57z M40.02,19.93
      	c-1.4,0-2.72-0.55-3.71-1.54l-0.88-0.88l-0.88,0.88c-0.99,0.99-2.31,1.54-3.71,1.54c-1.18,0-2.29,0.46-3.12,1.29
      	c-0.23,0.23-0.42,0.48-0.59,0.74c-0.57,0.04-1.1,0.2-1.58,0.46c0.73-4.8,4.88-8.49,9.87-8.49s9.14,3.69,9.87,8.49
      	c-0.48-0.26-1.01-0.42-1.58-0.46c-0.17-0.26-0.36-0.51-0.59-0.74C42.31,20.39,41.2,19.93,40.02,19.93z M26.47,27.68
      	c-0.62-0.34-1.04-0.99-1.04-1.74c0-0.74,0.41-1.38,1.01-1.73c0,0.05-0.01,0.09-0.01,0.14v2.59C26.43,27.18,26.45,27.43,26.47,27.68z
      	 M44.42,24.2c0.6,0.34,1.01,0.99,1.01,1.73c0,0.75-0.42,1.4-1.04,1.74c0.02-0.25,0.04-0.49,0.04-0.74v-2.59
      	C44.43,24.3,44.42,24.25,44.42,24.2z M18.43,22.58c0-1.35,0.64-2.64,1.71-3.47l11.12-8.55l1.65-4.13c0.12-0.3,0.41-0.5,0.73-0.5
      	c0.44,0,0.79,0.35,0.79,0.79v5.26c-6.15,0.51-11,5.67-11,11.95v2c0,0.45,0.09,0.87,0.23,1.27l-2.62,1.31l-2.08-3.86
      	C18.61,24.02,18.43,23.3,18.43,22.58z M21.98,30.28l2.78-1.39c0.59,0.54,1.34,0.89,2.18,1c0.66,1.88,1.91,3.48,3.55,4.56
      	c0.26,2.51,2.37,4.49,4.95,4.49s4.69-1.97,4.95-4.49c1.63-1.08,2.89-2.68,3.54-4.56c0.83-0.1,1.59-0.46,2.18-1l2.78,1.39l-3.45,6.41
      	v11.25h-20V36.68L21.98,30.28z M31.05,57.93l1.76-3.53c0.15-0.29,0.44-0.47,0.76-0.47c0.47,0,0.85,0.38,0.85,0.85v9.15h-6v-6H31.05z
      	 M36.43,54.79c0-0.47,0.38-0.85,0.85-0.85c0.33,0,0.62,0.18,0.76,0.47l1.76,3.53h2.62v6h-6V54.79z M52.43,63.93h-8v-6h6v-2h-9.38
      	l-1.21-2.42c-0.49-0.97-1.47-1.58-2.55-1.58c-0.71,0-1.35,0.27-1.85,0.7c-0.5-0.43-1.14-0.7-1.85-0.7c-1.09,0-2.07,0.6-2.55,1.58
      	l-1.21,2.42H16.43v2h10v6h-8c-3.86,0-7-3.14-7-7s3.14-7,7-7h34c3.86,0,7,3.14,7,7S56.29,63.93,52.43,63.93z M52.43,55.93h2v2h-2
      	V55.93z M38.43,23.93h2v2h-2V23.93z M30.43,23.93h2v2h-2V23.93z M35.43,29.93c-1.1,0-2-0.9-2-2h-2c0,2.21,1.79,4,4,4s4-1.79,4-4h-2
      	C37.43,29.04,36.54,29.93,35.43,29.93z"
      />
    </>
  );
}
