import { styled } from 'utils/emotion'
import media from 'utils/mediaqueries'

export const LogoStyled = styled('div')({
  // padding: '24px 0',
    fontSize: '5em',
    marginBottom: '0.5em',
    marginTop: 0,
    fontWeight: 700,
    letterSpacing: 20,
    lineHeight: '1.2em',
    textTransform: 'uppercase',
    position: 'fixed',
    top: 0,
    left: 0,
    padding: 50,
    zIndex: 2,
    pointerEvents: 'none',
    width: '100%',
    transition: 'all 200ms ease-in-out',

    [media('desktop')]: {
      fontSize: '3em'
    },

    svg: {
      maxWidth: 100,
      width: 100,
      height: 'auto',
      verticalAlign: 'top',

      [media('desktop')]: {
        maxWidth: 200,
        width: 200,
      },

      path: {
        transition: 'all 500ms ease-in-out',
      }
    },

    span: {
      fontSize: '0.5em',
      lineHeight: '1em',
    }
},
props =>
  props.theme === 'yellow' && {
    h1: {
      color: '#ffff91',
    },

    'svg path': {
      fill: '#ffff91 !important',
    },
  },
props =>
  props.theme === 'dark' && {
    h1: {
      color: '#ff0056',
    },

    'svg path': {
      fill: '#ff0056 !important',
    },
  }
)
