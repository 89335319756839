import styled from '@emotion/styled';

export const LayoutStyled = styled('div')({
  minHeight: '100vh',
  position: 'relative',
  paddingBottom: 40,
},
props =>
  props.theme === 'yellow' && {
    'a:hover': {
      textDecorationColor: '#0b5394',
    },

    body: {
      backgroundColor: '#ffff91',
    },
  }
)
