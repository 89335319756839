import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { HeaderNavStyled } from './HeaderStyled.js';

export default function HeaderNav({theme}) {
  return (
      <HeaderNavStyled className="main-nav" theme={theme}>
        <nav>
          <Link to="/">home</Link>
          <Link to="/vision">vision</Link>
          {/* <Link to="/events">events</Link> */}
        </nav>
      </HeaderNavStyled>
  );
}

HeaderNav.propTypes = {
  theme: PropTypes.string,
};

HeaderNav.defaultProps = {
  theme: 'neutral',
};
